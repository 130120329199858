import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dateToText } from '../components/application/global.function';
import aggrQueryParams from '../helpers/aggrQueryParams';
import {
    IHistoryEventList,
    IHistoryEvents,
    IHistoryEventsFilterParams,
    IHistoryFilterItem,
    IHistoryItemHtmlList,
} from '../interfaces/history.interface';
import { ILabSize } from '../interfaces/lab.interface';
import { IService, IServiceSize } from '../interfaces/services';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    constructor(private httpNew: HttpNewService, private auth: AuthService) {}

    /**
     * Количество истории (Visit Diag)
     * @param parameters
     */
    public getHistoryEventsSize(params: {
        beginDate: Date | null;
        endDate: Date | null;
        specID: number | null;
        docID: number | null;
        depID: number | null;
    }): Observable<ILabSize> {
        let url = '/history/events/size';

        const queryParams = aggrQueryParams(params);

        if (params.beginDate)
            queryParams.beginDate = dateToText(params.beginDate);
        if (params.endDate) queryParams.endDate = dateToText(params.endDate);

        return this.httpNew.get(url, queryParams);
    }

    public getHistoryEventsFilter(
        params: IHistoryEventsFilterParams
    ): Observable<IHistoryFilterItem[]> {
        const url = `/history/events/filters`;
        const queryParams = aggrQueryParams(params);
        return this.httpNew.get(url, queryParams);
    }

    /**
     * Список истории (Visit Diag)
     * @param parameters
     */
    public getHistoryEvents(
        beginDate: Date | null,
        endDate: Date | null,
        start?: number,
        end?: number,
        specID?: number | null,
        docID?: number | null,
        depID?: number | null
    ): Observable<IHistoryEvents[]> {
        let url = '/history/events';

        const queryParams = aggrQueryParams({
            beginDate,
            endDate,
            start,
            end,
            specID,
            docID,
            depID,
        });

        if (beginDate) queryParams.beginDate = dateToText(beginDate);
        queryParams.endDate = dateToText(endDate || new Date());
        // if (endDate) {queryParams.endDate = dateToText(endDate)} else {queryParams.endDate = dateToText}

        // if (pStart && pEnd) {
        //     url = url + `?start=${pStart}&end=${pEnd}`;
        //     if (beginDate && endDate) {
        //         url =
        //             url +
        //             `&beginDate=${dateToText(beginDate)}&endDate=${dateToText(
        //                 endDate
        //             )}`;
        //     }
        //     if (!beginDate && endDate) {
        //         url = url + `&endDate=${dateToText(endDate)}`;
        //     }
        // } else {
        //     if (beginDate && endDate) {
        //         url =
        //             url +
        //             `?beginDate=${dateToText(beginDate)}&endDate=${dateToText(
        //                 endDate
        //             )}`;
        //     }
        //     if (!beginDate && endDate) {
        //         url = url + `&endDate=${dateToText(endDate)}`;
        //     }
        // }

        return this.httpNew.get(url, queryParams);
    }

    /**
     * Список истории за указанный промежуток времени
     * @param parameters
     * @returns {Observable<IHistoryEventList[]>}
     */
    public getHistoryEventList(parameters: {
        begin: string;
        end: string;
    }): Observable<IHistoryEventList[]> {
        const { begin, end } = parameters;
        const url =
            '/api/patient/' +
            null +
            '/history/event?beginDate=' +
            begin +
            '&endDate=' +
            end;
        return this.httpNew.get(url);
    }

    /**
     * Получить событие по его типу и идентификатору
     * @param parameters
     * @returns {Observable<IHistoryVisit[] | IHistoryDiag[]>}
     */
    public getHistoryItem(parameters: {
        id: number;
        typeHistory: string;
    }): Observable<IHistoryItemHtmlList[]> {
        const url = `/history/events/item?typeRes=${parameters.typeHistory}&id=${parameters.id}`;
        return this.httpNew.get(url);
    }

    /**
     * Получить список услуг
     */

    public getServices(
        beginDate: Date | null,
        endDate: Date | null,
        pStart?: number,
        pEnd?: number
    ): Observable<IService[]> {
        const param = new Array();

        if (beginDate) {
            param.push('beginDate=' + dateToText(new Date(beginDate)));
        }

        if (endDate) {
            param.push('endDate=' + dateToText(new Date(endDate)));
        }
        if (pStart && pEnd) {
            param.push('start=' + pStart);
            param.push('end=' + pEnd);
        }

        const pr = param.join('&');
        const url = '/service/patient?' + pr;
        return this.httpNew.get(url);
    }

    /**
     * Получить список услуг - количество записей
     */

    public getServicesSize(
        beginDate: Date | null,
        endDate: Date | null
    ): Observable<IServiceSize> {
        const param = new Array();

        if (beginDate) {
            param.push('beginDate=' + dateToText(new Date(beginDate)));
        }

        if (endDate) {
            param.push('endDate=' + dateToText(new Date(endDate)));
        }

        const pr = param.join('&');
        const url = '/service/patient/size?' + pr;
        return this.httpNew.get(url);
    }
}
