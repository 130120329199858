import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { rightPanelAnimation } from 'src/app/animations/height.animation';
import { ServModalComponent } from 'src/app/modals/service-modal/service-modal.component';
import { ConfigService } from 'src/app/services/application/config.service.';
import { AlertService } from '../../../lib/ariadna/alert';
import {
    IRnumbDate,
    IServ,
    IStaticFilter,
} from '../../interfaces/record.interface';
import { HttpNewService } from '../../services/application/http-new.service';
import { RecordService } from '../../services/record.service';

interface Services {
    code: string;
    is_online_pay: 1 | 0;
    is_telemed: 1 | 0;
    keyid: number;
    price: number;
    text: string;
}

interface Doctor {
    docdepid: number;
    docid: number;
    specid: number;
    specid_1: string;
    text: string;
}

@Component({
    selector: 'rec-services',
    templateUrl: './rec-services.component.html',
    styleUrls: ['./rec-services.component.scss'],
    animations: [rightPanelAnimation],
})
export class RecServicesComponent implements OnInit {
    constructor(
        private httpService: HttpNewService,
        private alertService: AlertService,
        private rs: RecordService,
        private config: ConfigService,
        private modalService: NgbModal,
        private recordService: RecordService,
        private cdref: ChangeDetectorRef
    ) {}
    panelSetStyleRnum = {
        height: '100%',
        'box-shadow': '-7px 3px 4px 0px rgb(212 212 212 / 50%)',
    };
    panelSetStyle = { height: '100%' };

    loadingServices = true;
    loadingImgUrl = '../../../assets/loading-rec.gif';
    filterList: IStaticFilter[] = [];

    selectedSrv: IServ | undefined;
    selectedDoctor: Doctor | undefined;
    filterSearchText = '';
    services: IServ[] = [];
    doctors: Doctor[] | undefined;
    paramsTalonPicker!: IRnumbDate;
    staticFilterBTNs: IStaticFilter[] = []; // Список кнопок
    selectedFilter: IStaticFilter | null = null; //Список сервисов
    isDropdownOpen = false;
    showDoctors = true;
    showTalonPicker = false;
    talonPickerAnime = 'off';
    staticFilterSelected: any;
    searchAmine = false;

    ngOnInit(): void {
        moment.locale('ru');
        this.getServicesFullList(null);

        this.getStaticFilterList();

        // lastValueFrom(this.recordService.getStacfilterList())
        // .then((data: IStaticFilter[]) => {
        //     this.filterList = data
        //     console.log("filterList",this.filterList)
        // })
        // .catch((error) => {
        //     this.alertService.error(error.msg || 'Не удалось загрузить список услуг по данному фильтру.')
        // })
    }

    private getStaticFilterList(): void {
        this.recordService
            .getStacfilterList()
            .subscribe((data: IStaticFilter[]) => {
                this.filterList = data;
            });
    }

    async getServicesFullList(value: number | null) {
        this.loadingServices = true;
        this.staticFilterSelected = value;
        this.showTalonPicker = false;

        try {
            const res: IServ[] = await lastValueFrom(
                this.httpService.post('/record/rnumb/services-full-list', {
                    stacId: value ?? null,
                })
            );

            if (!res) {
                this.alertService.error(
                    'Нет свободных врачей на даннную услугу'
                );
            }

            this.services = res.sort((a, b) => {
                const aText = a.text.toUpperCase(),
                    bText = b.text.toUpperCase();
                if (aText < bText) {
                    return -1;
                }
                if (aText > bText) {
                    return 1;
                }
                return 0;
            });

            this.loadingServices = false;
        } catch (e) {
            console.log(e);
        } finally {
            this.loadingServices = false; //скрываем loader
        }
    }

    isEmptyObject(obj: Object) {
        return JSON.stringify(obj) === '{}';
    }

    async pickDoctor(doctor: Doctor) {
        try {
            this.selectedDoctor = doctor;

            const periodStart = new Date();
            const periodEnd = moment(periodStart).add(30, 'days').toDate();

            this.paramsTalonPicker = {
                doctorId: this.selectedDoctor.docid,
                specId: this.selectedDoctor.specid,
                periodStart,
                periodEnd,
                srv: this.selectedSrv,
            };

            this.showTalonPicker = true;
            this.talonPickerAnime = 'on';
        } catch (e) {
            console.log(e);
        }
    }

    async pickService(service: IServ) {
        try {
            this.selectedSrv = service;
            this.loadingServices = true;
            const res = await lastValueFrom(
                this.httpService.get(`/doctor/findByServices/${service.keyid}`)
            );

            this.doctors = res;
            this.loadingServices = false;
            this.showDoctors = true;
        } catch (e: any) {
            this.loadingServices = false;
            if (e.status === 400) {
                return this.alertService.error(e.error.msg);
            }
        }
    }

    getDocImg(id: number) {
        return `${this.config.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    getDocImgDefault(event: Event) {
        const elem = event.target as HTMLImageElement;
        elem.src = `${this.config.getValue('hostBackend')}/img/doc/not.png`;
    }

    openServModal(serv: IServ, event: Event) {
        event.stopPropagation();
        const modal = this.modalService.open(ServModalComponent);
        modal.componentInstance.serv = serv;
    }

    selectFilter(filter: IStaticFilter): void {
        if (this.staticFilterSelected !== filter.keyid) {
            this.selectedFilter = filter;
            this.doctors = [];
            this.filterSearchText = '';
            this.getServicesFullList(this.selectedFilter.keyid);
            this.staticFilterSelected = filter.keyid;
        } else {
            // this.selectedFilter = filter.keyid;
            this.doctors = [];
            this.filterSearchText = '';
            this.getServicesFullList(null);
            this.staticFilterSelected = null;
        }
        this.toggleDropdown();
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        this.searchAmine = true;
    }

    openDropdown() {
        this.toggleDropdown();
    }

    onTalonPickerClosed() {
        this.showTalonPicker = false;
    }

    getShortName(fullName: string): string {
        const words = fullName.split(' ');
        return (
            words[0] + ' ' + words[1].charAt(0) + '.' + words[2].charAt(0) + '.'
        );
    }

    onFilterChanged(event: any) {
        switch (event.typeEvent) {
            case 'clearFilter':
                this.filterList = event.data;
                this.staticFilterSelected = null;
                this.toggleDropdown();
                this.getServicesFullList(null);
                this.filterSearchText = '';
                this.doctors = [];
                break;

            case 'getStacFilters':
                this.filterList = event.data;
                break;

            case 'filterListUpdated':
                this.getServicesFullList(event.data || null);
                this.cdref.detectChanges();
                this.staticFilterSelected = event.data;
                this.filterSearchText = '';
                this.doctors = [];
                this.toggleDropdown();
                break;
        }
    }
}
