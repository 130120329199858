import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/lib/ariadna/alert';
import {
    getNameDay,
    strToDate,
} from '../../components/application/global.function';
import { IPatient } from '../../interfaces/patient';
import { IPay, IPaySystem } from '../../interfaces/payment.interface';
import {
    IResRecord,
    ITalonInfo,
    ITalonModal,
    ITalonResAppointment,
    ITalonResAttrs,
} from '../../interfaces/record.interface';
import { ConfigService } from '../../services/application/config.service.';
import { LoadService } from '../../services/application/load.service';
import { PatientService } from '../../services/patient.service';
import { PaymentsService } from '../../services/payments.service';
import { RecordService } from '../../services/record.service';
import { RnumbService } from '../../services/rnumb.service';
import {
    MAX_FILE_SIZE,
    VISIT_ALLOWED_EXTENSIONS,
} from './../../interfaces/constants';

import { lastValueFrom } from 'rxjs';
import {
    EUploadStatus,
    IAttachedFile,
} from 'src/app/interfaces/document.interface';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { ProtocolService } from 'src/app/services/protocol.service';
import { Store } from 'src/app/services/store.service';
@Component({
    selector: 'app-new-talon-mobile',
    templateUrl: './talon.component.html',
    styleUrls: ['./talon.component.scss'],
})
export class NewTalonComponentMobile implements OnInit {
    page: 'talon' | 'record' | 'pay' | 'canselOK' | 'goToTeleComp' = 'talon'; // какую страницу показывать
    params: ITalonModal | null;
    talon: ITalonInfo | null = null;
    rnumbID: number | null;

    isResRecord: IResRecord = {
        recTalon: false,
        payOnline: false,
        payClinic: false,
    }; // Есть результат записи
    payParam: IPay = { phone: '', email: '', sum: 0, abon_id: 0 }; // Оплата
    private _patient!: IPatient;
    recToPay: boolean = false; // Записаться к врачу на "Оплатить"
    attrTalonRec: ITalonResAttrs | null = null; // Аттрибуты талона
    paymentid: number = 0; // Номер платежа
    //  paySystem: IPaySystem = {isPhone: false, isEmail:false, code: '', requiredFieldCodes: []}; // Тип платежной системы
    paySystem: IPaySystem | null = null; // Тип платежной системы
    //  private _paySystemInfo: IPaySystemInfo  | null ; // Платежные системы
    loadingTalon: boolean = false; // Загрузка
    loadingTalonInfo: boolean = true; //Загрузка информации о талоне
    cancelingTalon: boolean = false; // Флаг отмены номерка
    loadingPay: boolean = false; // Флаг загрузки оплаты платежа
    f_appoitment: boolean = false; // Флаг записи на талон
    WEB_LK_PAY_LIFETIME = 60; // Время жизни платежа
    error: string | null = null;
    getInfoErrorMessage = ''; // Ошибка при получение информации о номерке(Договор на платные услуги)
    payable: boolean = false;
    attachedFiles = new Map<string, IAttachedFile>();
    allowedExt = '';
    //TODO убрать после полного перехода на node-web-pay (не успевает создавать услугу с платежом, если быстро нажать "оплатить")
    debounced = true;
    canPayLater: boolean = false;
    patientName: string = '';

    constructor(
        // private window: Window,
        private configS: ConfigService,
        private rs: RecordService,
        private rnumbS: RnumbService,
        private alertService: AlertService,
        private paymentsService: PaymentsService,
        private patientService: PatientService,
        private load: LoadService,
        public dialogRef: MatDialogRef<NewTalonComponentMobile>,
        @Inject(MAT_DIALOG_DATA) public dataIN: ITalonModal,
        private router: Router,
        @Inject(APP_BASE_HREF) private baseHref: string,
        private uploadService: FileUploadService,
        private protocolService: ProtocolService
    ) {
        this.params = dataIN;
        this.rnumbID = this.params.rnumbID;
        this.allowedExt = VISIT_ALLOWED_EXTENSIONS.map((ext) => `.${ext}`).join(
            ','
        );

        if (dataIN.files) this.setFilesFromInput(dataIN.files);

        if (
            load.configData.pages &&
            load.configData.pages.payments &&
            load.configData.pages.payments.WEB_LK_PAY_LIFETIME
        ) {
            this.WEB_LK_PAY_LIFETIME = Number(
                load.configData.pages.payments.WEB_LK_PAY_LIFETIME
            );
        }

        if (load.configData.other?.payLater) this.canPayLater = true;
    }

    ngOnInit(): void {
        window.addEventListener('beforeunload', (event) => {
            localStorage.setItem(
                'talonBlock',
                JSON.stringify({
                    rnumbID: this.params?.rnumbID,
                    f_appoitment: this.f_appoitment,
                })
            );
        });
        this.page = 'talon';
        // console.log(this.params, this.params?.rnumbID);

        if (this.params && this.params.rnumbID) {
            // let rnumbID = this.params.rnumbID;
            // console.log(this.params);
            this.getInfo().catch((error) => {
                this.handlerInfoError(error);
            });
        } else {
            this.getInfoExtra().catch((error) => {
                this.handlerInfoError(error);
            });
        }

        const token = Store.getTokenFromCookie();
        Store.getDataString('ParentCookieName')
            ? this.getPatientInfo(true, token!)
            : this.getPatientInfo();
        // this.getPatientInfo();
        this.getPaySystem();
        if (this.dataIN.withUpload) this.setFilesFromDB();

        this.patientService.patientName$.subscribe((patientName) => {
            this.patientName = patientName;
        });

        // console.log('Patient Name:', this.patientName);
    }
    handlerInfoError(error: any) {
        this.loadingTalonInfo = false;
        this.alertService.warn(error.msg || 'Не удалось загрузить данные талона');
        console.error(error.msg);
        this.getInfoErrorMessage = error.msg;
    }
    /* Получить данные по пациенту*/
    getPatientInfo(isFamilyAccount = false, tokenValue?: string) {
        const result = lastValueFrom(
            this.patientService.getServerPatientInfo$(
                tokenValue,
                isFamilyAccount
            )
        );
        result
            .then((info) => {
                // console.log('getPatientInfo', info);
                this._patient = info;
                this.payParam.email = this._patient.email || '';
                this.payParam.phone =
                    this._patient.phone || this._patient.cellular || '';
            })
            .catch((error) => console.error('getPatientInfo', error));
    }

    /* Получить тип платежной системы телефон почта !*/
    private getPaySystem() {
        this.paySystem = null;

        this.paymentsService.getPaySystem().subscribe(
            (info) => {
                this.paySystem = info.paysystems[0];
                switch (this.paySystem.requiredfieldcodes[0]) {
                    case 'phone': {
                        this.paySystem.isPhone = true;
                        break;
                    }
                    case 'email': {
                        this.paySystem.isEmail = true;
                        break;
                    }
                }
            },
            (err) => {
                console.error('getPaySystem ERR1=', err);
            }
        );
    }

    async getInfo() {
        try {
            const res = await lastValueFrom(
                this.rs.getRnumbInfo(
                    this.params?.rnumbID!,
                    this.params?.srv?.keyid.toString()
                )
            );
            //
            if (res && res[0]) {
                this.talon = res[0];
                this.talon.beginDate = strToDate(this.talon.dat_bgn);
                this.talon.endDate = strToDate(this.talon.dat_end);
                this.loadingTalonInfo = false;
                //
                if (this.params && !this.params.srv) {
                    try {
                        const res = await lastValueFrom(
                            this.rnumbS.getRnumbSrv(this.params?.rnumbID!)
                        );
                        if (res && this.params) {
                            this.params.srv = res;
                        }
                    } catch (error: any) {
                        console.error(error.msg);
                    }
                }
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getInfoExtra() {
        try {
            const res = await lastValueFrom(
                this.rs.getRnumbInfoExtra({
                    docdepID: this.params?.info?.docdepID!,
                    date: this.params?.info?.beginDate!,
                    srvIDs: this.params?.srv?.keyid.toString()!,
                })
            );
            this.talon = res;
            this.loadingTalonInfo = false;
        } catch (error: any) {
            throw error;
        }
    }

    getImgSrcDoc(id: number | null | undefined): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param event
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue(
            'hostBackend'
        )}/img/${type}/not.png`;
    }

    /* День и время*/
    getTalonDateTime(dt: Date | undefined, withTime?: boolean): string {
        // console.log(dt, withTime);
        let str = '';
        if (!dt) return str;
        str = moment(dt).format('DD MMMM');
        if (withTime) str += ' в ' + moment(dt).format('HH:mm');

        return str;
    }

    // Предиката для определения является ли номерок записью без выбора времени
    // Т.е. если мы передали в модалку rnumbID, и у номерка время исполнения > 3 часов утра, то номерок считается записью без выбора времени
    withTime(): boolean {
        const date =
            this.talon?.dat_bgn ||
            this.params?.info?.beginDate?.toLocaleString('ru-RU');

        return (
            Boolean(this.params?.rnumbID) && moment(strToDate(date!)).hour() > 3
        );
    }

    /* День недели*/
    getNameDay(dt: Date | undefined): string {
        if (dt) {
            return getNameDay(dt);
        } else {
            return '';
        }
    }
    async onRecord() {
        try {
            if (!this.params) throw new Error('No talon params');
            this.loadingTalon = true;
            this.paymentid = 0;
            let serviceId: number | null = null;
            if (this.params.srv && this.params.srv.keyid) {
                serviceId = +this.params.srv.keyid;
                this.payParam.sum = this.params.srv.price;
            }
            let recordResponse: ITalonResAppointment | null = null;
            // console.log(this.params);
            if (this.rnumbID) {
                // С выбором времени
                recordResponse = await lastValueFrom(
                    this.rs.setAppointment(this.rnumbID, serviceId!)
                );
                if (recordResponse.error_code !== 0)
                    throw new Error(
                        `Запись невозможна, ${recordResponse.error_text}`
                    );
            } else {
                // Без выбора времени
                recordResponse = await this.onRecordWithoutRnumbID();
                if (!recordResponse?.rnumb_id) {
                    console.log('NO RNUMB ID');
                    this.alertService.error('Запись невозможна');
                    this.loadingTalon = false;
                    this.dialogRef.close();
                    return;
                }
                this.rnumbID = recordResponse.rnumb_id;
            }

            if (!recordResponse) throw new Error('No record response');

            this.f_appoitment = true;

            if (this.params && this.params.srv && this.params.srv.keyid) {
                const attrs = await lastValueFrom(
                    this.rs.getAttrs(this.rnumbID, serviceId)
                );
                this.attrTalonRec = attrs;
                this.recToPay = true;
                if (attrs.is_create_pay_order === 1) {
                    if (attrs.is_telemed === 1) {
                        await lastValueFrom(
                            this.rs.getСreateConference(this.rnumbID)
                        );
                    }
                    const resCPbyR = await lastValueFrom(
                        this.rs.getCPbyR(this.rnumbID, serviceId)
                    );
                    if (resCPbyR.err_code !== 0) {
                        throw new Error(
                            `Проблема с созданием платежа: ${resCPbyR.err_text}`
                        );
                    }
                    if (resCPbyR.paymentid) {
                        this.paymentid = resCPbyR.paymentid;
                    }

                    this.loadingTalon = false;
                }
                //Создание протокола, если к услуге привязана форма протокола
                if (recordResponse.form_id) {
                    await this.createProtocol(
                        +recordResponse.visit_id,
                        +recordResponse.form_id
                    );
                }
                if (
                    attrs.is_create_pay_order === 0 &&
                    attrs.is_online_pay === 0
                ) {
                    this.page = 'record';
                    this.isResRecord.recTalon = true;
                    this.loadingTalon = false;
                }
                this.payable = true; // посоны, я не разбирался в этом дерьме, но мне надо было вставить этот флаг (вроде сюда), не могу ручаться, что работает 100%
                this.loadingTalon = false;
                await new Promise((resolve) =>
                    setTimeout(() => {
                        this.debounced = false; // legacy hell for web-pay Tomcat
                        resolve(true);
                    }, 3500)
                );
            } else {
                this.page = 'record';
                this.isResRecord.recTalon = true;
                this.loadingTalon = false;
            }
        } catch (error) {
            console.log(error);
            this.handleError(error);
        }
    }

    async onRecordWithoutRnumbID() {
        return await lastValueFrom(
            this.rs.setAppointmentExtra(
                this.params?.info?.docdepID!,
                this.params?.srv?.keyid.toString()!,
                moment(this.params?.info?.beginDate).format('YYYY-MM-DD')
            )
        );
    }

    private async handleError(error: any) {
        if (this.params?.rnumbID && this.f_appoitment) {
            try {
                await lastValueFrom(
                    this.rs.getRnumbCancel(this.params?.rnumbID)
                );
            } catch (error: any) {
                console.error('Проблема с отменой записи на талон:', error.msg);
            }
        }
        this.alertService.error(error.msg);
        this.dialogRef.close({
            rnumbID: this.rnumbID,
            unlockTalon: true,
        });
        // console.log(error);
    }

    async createProtocol(visitId: number, formId: number) {
        await this.protocolService.createProtocol(visitId, formId);
    }

    onCanselPage() {
        this.page = 'canselOK';
    }
    onClickPopTelemed() {
        this.page = 'goToTeleComp';
    }
    onCanselRecord() {
        if (this.params && this.params.rnumbID) {
            this.cancelingTalon = true;
            lastValueFrom(this.rs.getRnumbCancel(this.params.rnumbID))
                .then((resCansel) => {
                    if (resCansel && resCansel.err_code === 0) {
                        this.alertService.success(
                            'Талон успешно отменен.',
                            4000
                        );
                        this.dialogRef.close({
                            rnumbID: this.params?.rnumbID,
                            canselRes: true,
                        });
                    } else {
                        if (resCansel && resCansel.err_text) {
                            this.showError(resCansel.err_text);
                        } else {
                            this.showError(
                                'Чтото пошло не так, попробуйте позже.'
                            );
                        }
                    }
                    this.cancelingTalon = false;
                })
                .catch((errCansel) => {
                    this.dialogRef.close();
                    this.alertService.error(errCansel.msg, 7000);
                    console.error('getRnumbCancel ERRROr=', errCansel);
                    this.cancelingTalon = false;
                });
        } else {
            // this.alertService.error('Нет номерка.')
            this.showError('Нет номерка.');
        }
    }
    //TODO убрать после полного перехода на node-web-pay (не успевает создавать услугу с платежом, если быстро нажать "оплатить")
    debouncePay() {
        setTimeout(() => {
            this.goToPay();
        }, 1000);
    }

    goToPay() {
        if (this.paymentid == 0) {
            this.loadingTalon = true;
            if (this.params && this.params.srv && this.params.srv.keyid) {
                lastValueFrom(
                    this.rs.getCPbyR(
                        this.params.rnumbID!,
                        this.params.srv.keyid
                    )
                )
                    .then((resCPbyR) => {
                        if (resCPbyR.paymentid) {
                            this.paymentid = resCPbyR.paymentid;
                        } else {
                            // this.alertService.error('Ошибка при создании платежа, не получили ID.');
                            this.showError(
                                'Ошибка при создании платежа, не получили ID.'
                            );
                        }
                        this.loadingTalon = false;
                    })
                    .catch((errCPbyR) => {
                        console.error('Ошибка при создании платежа.', errCPbyR);
                        // this.alertService.error('Ошибка при создании платежа.');
                        this.showError('Ошибка при создании платежа.');
                        this.loadingTalon = false;
                    });
            } else {
                // this.alertService.error('Нет услуги, нечего оплачивать.');
                this.showError('Нет услуги, нечего оплачивать.');
                this.loadingTalon = false;
            }
        }
        this.page = 'pay';
    }

    async goPay() {
        if (!this.rnumbID) {
            this.alertService.error('Оплата невозможна, нет номерка.');
            return;
        }
        let srvId: number | null = null;
        if (this.params && this.params.srv && this.params.srv.keyid) {
            srvId = this.params.srv.keyid;
        }
        this.loadingPay = true;

        if (this.attrTalonRec && this.attrTalonRec.is_online_pay == 1) {
            if (this.paymentid) {
                try {
                    const resPay = await lastValueFrom(
                        this.rs.getPaymentTemp(
                            this.paymentid,
                            this.payParam.email,
                            this.payParam.phone
                        )
                    );
                    //
                    if (resPay.identity && resPay.identity > 0) {
                        const resOrderPay = await lastValueFrom(
                            this.paymentsService.getPayOrder(resPay.identity)
                        );
                        //
                        if (resOrderPay.confirmationurl) {
                            window.open(resOrderPay.confirmationurl);
                            this.dialogRef.close();
                        } else {
                            // this.alertService.error('Нет УРЛА');
                            this.showError(
                                'Не получилось создать платеж в платежной системе !'
                            );
                        }
                    } else {
                        if (resPay.err_text && resPay.err_text.length > 2) {
                            // this.alertService.error(resPay.err_text)
                            this.showError(resPay.err_text);
                        } else {
                            // this.alertService.error('С платежами чтото не то.')
                            this.showError('С платежами чтото не то.');
                        }
                    }
                } catch (errPay) {
                    // this.alertService.error('Ошибка создания платежа, попробуйте повторить операцию позднее.');
                    this.showError(
                        'Ошибка создания платежа, попробуйте повторить операцию позднее.'
                    );
                    console.error('getPaymentTemp ERRROr=', errPay);
                } finally {
                    this.loadingPay = false;
                }
            } else {
                // Нет то генерим
                try {
                    const resCPbyR = await lastValueFrom(
                        this.rs.getCPbyR(this.rnumbID, srvId)
                    );
                    //
                    if (resCPbyR.paymentid) {
                        this.paymentid = resCPbyR.paymentid;
                    }
                    await lastValueFrom(
                        this.rs.getPaymentTemp(
                            this.paymentid,
                            this.payParam.email,
                            this.payParam.phone
                        )
                    );
                } catch (errCPbyR) {
                    console.error('getCPbyR ERRROr=', errCPbyR);
                } finally {
                    this.loadingPay = false;
                }
            }
        } else {
            if (this.paymentid) {
                try {
                    await lastValueFrom(
                        this.rs.getPaymentTemp(
                            this.paymentid,
                            this.payParam.email,
                            this.payParam.phone
                        )
                    );
                } catch (errPay) {
                    console.error('getPaymentTemp ERRROr=', errPay);
                }
            } else {
                // this.alertService.error('Нет ИД платежа');
                this.showError('Нет ИД платежа');
            }
            this.loadingPay = false;
        }
    }

    validPay(): boolean {
        let f = true;
        if (
            (this.paySystem &&
                this.paySystem.isPhone &&
                this.payParam.phone &&
                this.payParam.phone.length > 10) ||
            (this.paySystem &&
                this.paySystem.isEmail &&
                this.payParam.email &&
                this.payParam.email.length > 5)
        ) {
            f = false;
        }
        return f;
    }

    setSumm(): string {
        if (
            this.params &&
            this.params.srv &&
            this.params.srv.price &&
            this.params.srv.price > 0
        ) {
            return `${this.params.srv.price.toFixed(0)} рублей`;
        } else {
            return '';
        }
    }

    getFIODoc(talon: ITalonInfo | null): string {
        let s = '';
        if (talon) {
            if (talon.lastname && talon.lastname.length > 0) {
                s = talon.lastname;
            }
            if (talon.firstname && talon.firstname.length > 0) {
                s = s + ' ' + talon.firstname[0] + '.';
            }
            if (talon.secondname && talon.secondname.length > 0) {
                s = s + ' ' + talon.secondname[0] + '.';
            }
        }
        return s;
    }

    closeExit() {
        if (this.f_appoitment == true) {
            // Запись уже осуществилась
            this.dialogRef.close({
                rnumbID: this.params?.rnumbID,
                unlockTalon: false,
            }); // Закрываем
        } else {
            this.dialogRef.close({
                rnumbID: this.params?.rnumbID,
                unlockTalon: true,
            }); // Закрываем
        }
    }

    // Переход на телевидеоКонференцию
    goToTelemed(talon: ITalonInfo | null) {
        if (talon && talon.url_telemed && talon.url_telemed.length > 5) {
            window.open(talon.url_telemed, '_blank');
        }
    }

    showError(text: string) {
        this.error = text;
    }

    hideError() {
        this.error = null;
    }

    toPayments(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate([`/home/payments`]);
        this.dialogRef.close();
    }

    toHistory(event: MouseEvent) {
        event.stopPropagation();
        this.router.navigate([`/home/history`]);
        this.dialogRef.close();
    }

    toRecord(event: MouseEvent) {
        event.stopPropagation();
        this.router.navigate([`/home/rec`]);
        this.dialogRef.close();
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        const { dataTransfer } = event;
        if (!dataTransfer) return;

        const filelist = new DataTransfer();

        if (dataTransfer.items) {
            for (let i = 0; i < dataTransfer.items.length; i++) {
                if (dataTransfer.items[i].kind === 'file') {
                    const file = dataTransfer.items[i].getAsFile();
                    if (!file) continue;
                    filelist.items.add(file);
                }
            }
            this.setFilesFromInput(filelist.files);
            filelist.items.clear();
            dataTransfer.items.clear();
        }

        this.attachedFiles.forEach((file) => {
            this.uploadFile(
                file?.file!,
                this.params?.tag!,
                this.params?.link_id!
            );
        });
    }

    async uploadFile(file: File, tag: number, id: number) {
        try {
            const res = await this.uploadService.uploadFile(file, tag, id);
            if (!res?.id) {
                throw new Error(`Не удалось сохранить файл ${file.name}`);
            }
            this.attachedFiles.set(file.name, {
                ...this.attachedFiles.get(file.name)!,
                id: Number(res?.id),
                status: EUploadStatus.SUCCESS,
                link_id: res?.id!,
                storage_path: res.storage_path,
            });
        } catch (error: any) {
            this.alertService.error(
                `Не удалось сохранить файл ${file.name} \n ${
                    error.msg || error.message
                }`
            );
            this.deleteFile(file.name, id);
        }
    }

    async deleteFile(name: string, id: number) {
        this.attachedFiles.set(name, {
            ...this.attachedFiles.get(name)!,
            status: EUploadStatus.LOADING,
        });

        if (this.attachedFiles.get(name))
            await this.uploadService.deleteFileFromDB(
                this.attachedFiles.get(name)?.storage_path,
                id
            );
        this.attachedFiles.set(name, {
            ...this.attachedFiles.get(name)!,
            status: EUploadStatus.SUCCESS,
        });

        this.attachedFiles.delete(name);
    }

    deleteFileHandler(event: Event, name: string, id: number) {
        event.stopPropagation();
        event.preventDefault();
        this.deleteFile(name, id);
    }

    getExt(name: string): string {
        return name.split('.').pop() ?? '';
    }

    onChangeInputUpload(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files;

        if (!files) {
            console.log('NO FILES IN INPUT');
            return;
        }

        this.setFilesFromInput(files);
    }

    async setFilesFromInput(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!file) continue;
            const ext = this.getExt(file.name);
            const name = file.name.substring(0, file.name.indexOf(`.${ext}`));

            if (!VISIT_ALLOWED_EXTENSIONS.includes(ext)) {
                this.alertService.warn(
                    `Файл ${file.name} имеет неподходящее расширение`
                );
                continue;
            }

            if (file.size >= MAX_FILE_SIZE) {
                this.alertService.warn(`Файл ${file.name} силшком большой!`);
                continue;
            }

            if (this.attachedFiles.has(file.name)) {
                this.alertService.warn(
                    `${file.name} уже существует в базе или загружется сейчас`
                );
                continue;
            }

            this.attachedFiles.set(file.name, {
                file,
                ext,
                name,
                status: EUploadStatus.LOADING,
                id: null,
                link_id: null,
                storage_path: '',
            });

            this.uploadFile(files[i], this.params?.tag!, this.params?.link_id!);
        }
    }

    // Get already uploaded files from db and set to attacheFiles
    async setFilesFromDB() {
        const filelist = await this.uploadService.getFilelist(
            this.params?.tag!,
            this.params?.link_id!
        );

        filelist.forEach((file) => {
            this.attachedFiles.set(`${file.name}${file.file_type}`, {
                name: file.name,
                id: file.id,
                ext: file.file_type,
                status: EUploadStatus.SUCCESS,
                link_id: this.params?.link_id!,
                storage_path: file.storage_path || '',
            });
        });
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    onDragOver(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    payLater() {
        this.page = 'record';
    }
}
