/* Компонент раздела "Запись к врачу" мобильной версии */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { AlertService } from 'src/lib/ariadna/alert';
import {
    expandedAnimation,
    rightPanelAnimation,
} from '../../../animations/height.animation';
import { IPeriod } from '../../../interfaces/period.interface';
import {
    IDoctor,
    IRnumbDate,
    IServ,
    ISpec,
    IStaticFilter,
} from '../../../interfaces/record.interface';
import { IService } from '../../../interfaces/services';
import { ConfigService } from '../../../services/application/config.service.';
import { LoadService } from '../../../services/application/load.service';
import { RecordService } from '../../../services/record.service';
import { Size } from '../../../services/size';
import { strToDate } from '../../application/global.function';
import { DocInfoModalComponent } from 'src/app/modals/doc-info-modal/doc-info-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DoctorService } from 'src/app/services/doctor.service';
import { lastValueFrom } from 'rxjs';
import { ServDescModalComponent } from 'src/app/modals/srv-desc-modal/srv-desc-modal.component';

@Component({
    selector: 'app-rec-mobile',
    templateUrl: './rec-mobile.component.html',
    styleUrls: ['./rec-mobile.component.scss'],
    animations: [expandedAnimation, rightPanelAnimation],
})
export class RecMobileComponent implements OnInit {
    //    blockBodystyleHeight = {height:'auto'};
    panelSetStyle = { height: '100%' };
    panelSetStyleRnum = {
        height: '94%',
        'box-shadow': '-7px 3px 4px 0px rgb(212 212 212 / 50%)',
    };
    bezUslug = true;
    loadingListSpec = false; // Загрузка специальностей
    loadingListDoc = false; // Загрузка докторов
    isPageLoading = false;

    @ViewChild('InputBlocSpec') InputBlocSpecEL: ElementRef | undefined;

    _specList: ISpec[] = []; // полный список
    specList: ISpec[] = []; // Отфильтрованный
    searchAmine = 'off';
    panelRnumbAmine = 'off';
    staticFilterBTNs: IStaticFilter[] = []; // Список кнопок
    staticFilterSelected: IStaticFilter | null = null; // выбраные фильтр

    selectDoc: IDoctor | null = null;
    periodStart: Date = new Date();
    periodEnd: Date = new Date();

    selectedSpec: ISpec | null = null;
    shownServicesDoc: IDoctor | null = null;

    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    orderUP = true; // С начала последние
    orderUPText: string = 'Сначала последние';

    recList: IService[] | null = null;
    recDoctors: IDoctor[] | null = null;
    selectedSrv: IServ | null = null; // Выбранная услуга

    recListLength: number = 0; // Количество записей

    loadingRecList = false; // Загрузка
    loadingSpecList = false; // Загрузка специальностей
    private _setting: any; // Настройки из файла

    searchString = '';

    /* Создание компонента */
    constructor(
        private ds: DoctorService,
        private size: Size,
        private configS: ConfigService,
        private rs: RecordService,
        private title: Title,
        private load: LoadService,
        private alert: AlertService,
        private modalService: NgbModal
    ) {
        moment.locale('ru');
        this._setting = load.configData.pages.todoctor;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }
    }

    /* Инициализация компонента */
    ngOnInit(): void {
        this.isPageLoading = true;
        this.init().then(() => (this.isPageLoading = false));
    }

    async init() {
        document.body.classList.add('mobile-body');
        // Установка периода
        this.periodStart = new Date();
        this.periodEnd = moment(this.periodStart).add(30, 'days').toDate();

        this.btnFiltrTek();
        await this.getStaticfilterBTN();
        await this.getSpecList();
        this.selectedSpec = null;
        this.selectedSrv = null;
    }

    /* После инициализации */
    ngAfterViewInit() {
        if (this.InputBlocSpecEL) {
            this.InputBlocSpecEL.nativeElement.focus();
        }
    }

    /* Получение списка специальностей */
    async getSpecList() {
        this.loadingSpecList = true;
        this.specList = [];

        let staticId: number | null = null;
        if (this.staticFilterSelected?.keyid) {
            staticId = this.staticFilterSelected.keyid;
        }

        try {
            const specList = await lastValueFrom(
                this.rs.getSpecList(this.periodStart, this.periodEnd, staticId)
            );

            this._specList = specList;
            this.specList = specList;
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка специальностей');
        } finally {
            this.loadingSpecList = false;
        }
    }

    /* Получение списка докторов */
    getDocList(p_SpecID: number) {
        //    this.selectedSpec = p_SpecID;
        this.recDoctors = [];
        this.loadingListDoc = true;

        let staticId: number | null = null;
        if (this.staticFilterSelected && this.staticFilterSelected.keyid) {
            staticId = this.staticFilterSelected.keyid;
        }

        this.rs
            .getDocList(p_SpecID, this.periodStart, this.periodEnd, staticId)
            .subscribe(
                (val) => {
                    this.recDoctors = val;
                    this.recDoctors.forEach((item) => {
                        item.rdatemin = strToDate(item.dat_bgn);
                        item.rdatemax = strToDate(item.dat_end);
                    });

                    const docInfoPromises = this.recDoctors.map((doc) =>
                        lastValueFrom(this.ds.getDocAboutInfo(doc.doctorid))
                    );
                    Promise.allSettled(docInfoPromises)
                        .then((res) => {
                            res.forEach((info, i) => {
                                if (
                                    this.recDoctors !== null &&
                                    info.status === 'fulfilled'
                                ) {
                                    this.recDoctors[i].info = info.value;
                                }
                            });
                        })
                        .catch(console.log)
                        .finally(() => {
                            this.loadingListDoc = false;
                        });
                },
                (error) => {
                    console.error(error);
                    this.loadingListDoc = false;
                }
            );
    }

    /* Выбор специальности и фильтрация списка докторов и его вывод */
    selectSpec(srvID: number) {
        this.selectedSpec = this.specList.filter(
            (item) => item.keyid == srvID
        )[0];
        this.searchAmine = 'off';
        this.searchString = this.selectedSpec.text;
        this.getDocList(srvID);
    }

    /* Поиск специальности */
    specSearch(event: Event) {
        let str = '';
        const target = event.target as HTMLInputElement;

        if (target && target.value) {
            str = target.value;
        }

        this.changeSearch(str);
    }

    resetSearch() {
        this.changeSearch('');
    }

    /* Фильтрация списка специальностей */
    changeSearch(str: string) {
        this.selectedSpec = null;
        this.selectedSrv = null;
        this.selectDoc = null;

        this.specList = this.filterSpecsByString(str);
    }

    filterSpecsByString(searchString: string) {
        let filteredSpecs: ISpec[] = [];
        if (searchString.length > 0) {
            filteredSpecs = this._specList.filter(
                ({ text }) =>
                    text.toLowerCase().indexOf(searchString.toLowerCase()) !==
                    -1
            );
        } else {
            filteredSpecs = this._specList;
        }

        return filteredSpecs;
    }

    /* Показ фильтра по специальностям */
    searchClick() {
        this.panelRnumbAmine = 'off';
        if (this.searchAmine === 'off') {
            this.searchAmine = 'on';
        } else {
            this.searchAmine = 'off';
        }

        // Если специальность не меняется
        if (this.searchAmine === 'off' && this.selectDoc) {
            if (this.selectDoc.srvlist && this.selectDoc.srvlist.length > 0) {
                if (this.selectedSrv) {
                    this.panelRnumbAmine = 'on';
                }
            } else {
                this.panelRnumbAmine = 'on';
            }
        }
    }

    /* Изменение периода поиска талонов */
    changePeriod(dt: IPeriod) {
        this.dtBegin = dt.begin;
        this.dtEnd = dt.end;
    }

    /* Изменение периода фильтра - сегодня */
    btnFiltrTek() {
        this.dtBegin = new Date();
        this.dtEnd = new Date();
        this.changePeriod({ begin: this.dtBegin, end: this.dtEnd });
    }

    /* Получение строки из выбранных дат в фильтре */
    getPeriodStr(dt1?: Date, dt2?: Date): string {
        let tmp = '';
        if (dt1?.getMonth() == dt2?.getMonth()) {
            // Одинаковый месяц
            tmp =
                '(' +
                moment(dt1).format('D') +
                ' - ' +
                moment(dt2).format('D MMM') +
                ')';
        } else {
            tmp =
                '(' +
                moment(dt1).format('D MMM') +
                ' - ' +
                moment(dt2).format('D MMM') +
                ')';
        }
        return tmp;
    }

    /* Получение изображения специальности */
    getImgSrcSpec(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/spec/${id}.png`;
    }

    /* Получение изображения доктора */
    getImgSrcDoc(id: number): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param event
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue(
            'hostBackend'
        )}/img/${type}/not.png`;
    }

    /* Выбор доктора */
    selectedDoc(doc: IDoctor) {
        if (this.selectDoc != doc) {
            this.selectDoc = doc;

            this.RnumbDateSetParams();
        }
    }
    openServDescModal(docSrv: IServ, event: Event) {
        event.stopPropagation();
        const modal = this.modalService.open(ServDescModalComponent);
        modal.componentInstance.docSrv = docSrv;
        modal.componentInstance.spec = this.selectedSpec?.text;
    }

    getImgSrcErr() {
        return `${this.configS.getValue('hostBackend')}/img/doc/not.png`;
    }

    /* передача параметров в номерки */
    RnumbDateSetParams() {
        if (
            this.selectDoc &&
            this.selectDoc.srvlist &&
            this.selectDoc.srvlist.length > 0
        ) {
       
            // с услугами
            if (this.selectedSrv ) {
             
                this.panelRnumbAmine = 'on'; // Включить анимацию

                let param: IRnumbDate = {
                    doctorId: 0,
                    specId: 0,
                    periodStart: new Date(),
                    periodEnd: new Date(),
                    srv: this.selectedSrv,
                };
                param.doctorId = this.selectDoc?.doctorid;
                if (this.selectedSpec) {
                    param.specId = this.selectedSpec.keyid;
                }
                param.periodStart = this.periodStart;
                param.periodEnd = this.periodEnd;
                if (this.staticFilterSelected) {
                    param.staticFilterSelected = this.staticFilterSelected;
                    console.log(4)
                }
                this.rs.RnumbDateSetParams(param);
            } else {
                this.panelRnumbAmine = 'off';
            }
        } else {
            // Без услуг
         
            this.panelRnumbAmine = 'on'; // Включить анимацию
            let param: IRnumbDate = {
                doctorId: 0,
                specId: 0,
                periodStart: new Date(),
                periodEnd: new Date(),
            };

            param.doctorId = this.selectDoc?.doctorid!;

            if (this.selectedSpec) {
                param.specId = this.selectedSpec.keyid;
            }
            param.periodStart = this.periodStart;
            param.periodEnd = this.periodEnd;
            if (this.staticFilterSelected) {
                param.staticFilterSelected = this.staticFilterSelected;
            }

            this.rs.RnumbDateSetParams(param);
        }
    }
    /* Список кнопок */
    async getStaticfilterBTN() {
        this.staticFilterBTNs = [];
        this.staticFilterSelected = null;

        try {
            const val = await lastValueFrom(this.rs.getStacfilterList());
            this.staticFilterBTNs = val;
        } catch (error) {
            this.alert.error('Ошибка плучения списка');
        }
    }

    /* Фильтрация специальностей при выборе в фильтре специальностей */
    btnStaticFiltr(btnST: IStaticFilter) {
        if (this.staticFilterSelected == btnST) {
            this.staticFilterSelected = null;
        } else {
            this.staticFilterSelected = btnST;
        }
        this.selectDoc = null;
        this.selectedSpec = null;
        this.selectedSrv = null;
        this.getSpecList();
    }

    /* Закрытие календаря с талонами */
    onClosePanelTalon(event: number) {
        this.selectDoc = null;
        this.panelRnumbAmine = 'off';
    }

    /* Показывается ли список услуг доктора */
    isShowSrvList(doc: IDoctor): boolean {
        if (doc && doc.srvlist && doc.srvlist.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    /* Некорректная услуга выбрана */
    invalidSrv(doctorid: number): boolean {
        let f: boolean = false;
        if (
            this.selectDoc &&
            this.selectDoc.doctorid == doctorid &&
            !this.selectedSrv
        ) {
            f = true;
        }
        return f;
    }

    /* Показать услуги определённого доктора */
    showServices(doc: IDoctor) {
        this.shownServicesDoc = doc;
    }

    /* Скрыть услуги */
    hideServices() {
        this.shownServicesDoc = null;
    }

    /* Выбор специальности */
    selectedServise(doc: IDoctor, srv: IServ) {
        this.selectDoc = doc;
        this.selectedSrv = srv;

        // Закомментил этот трэш, по идее никак не должно влиять

        // var leftHeader = document.querySelectorAll<HTMLElement>('.sidebar')[0],
        //     mainHeader = document.querySelectorAll<HTMLElement>('.header')[0];
        // leftHeader.style.display = 'none';
        // mainHeader.style.display = 'none';
        // document.body.style.backgroundColor = '#ffffff';
        this.RnumbDateSetParams();
    }

    /* Получение ФИО доктора */
    getFIODoc(doc: IDoctor): string {
        let s = '';
        if (doc.l_name && doc.l_name.length > 0) {
            s = doc.l_name;
        }
        if (doc.f_name && doc.f_name.length > 0) {
            s = s + ' ' + doc.f_name[0] + '.';
        }
        if (doc.s_name && doc.s_name.length > 0) {
            s = s + ' ' + doc.s_name[0] + '.';
        }
        return s;
    }

    /* Сброс выбора услуги и (если resetDoc = true) - доктора */
    resetChoice(resetDoc: boolean = false) {
        // this.selectedSpec = null;
        if (resetDoc) {
            this.selectDoc = null;
            this.shownServicesDoc = null;
        }
        this.selectedSrv = null;
        var leftHeader = document.querySelectorAll<HTMLElement>('.sidebar')[0],
            mainHeader = document.querySelectorAll<HTMLElement>('.header')[0];
        leftHeader.style.display = 'block';
        mainHeader.style.display = 'block';
        document.body.style.backgroundColor = '#F5FBFF';
    }

    /* Сброс фильтра спеицальностей */
    clearFilter() {
        this.staticFilterSelected = null;
        this.getSpecList();
        this.searchAmine = 'off';
    }
    openDocInfoModal(doc: IDoctor, event: Event) {
        event.stopPropagation();
        const modal = this.modalService.open(DocInfoModalComponent);
        modal.componentInstance.doc = doc;
        modal.componentInstance.spec = this.selectedSpec?.text;
    }
}
