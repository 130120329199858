<div
    [@showOpacityAnimation]="animState"
    (@showOpacityAnimation.done)="whenAnimate($event)"
>
    <form class="reg-form" [formGroup]="frmRegPhone" *ngIf="step == 0">
        <div class="loading-group">
            <div *ngIf="loading" class="loading">
                <!-- <img src="./assets/loading-rec.gif" /> -->
                <loader type="page"> </loader>
            </div>

            <mat-form-field class="w-100">
                <mat-label>Телефон</mat-label>
                <span matTextPrefix>+7 &nbsp;</span>
                <input
                    [type]="'text'"
                    #fieldPhone
                    matInput
                    placeholder="(999) 999-9999"
                    formControlName="phone"
                    phoneMask
                    [preValue]="frmRegPhone.value.phone"
                    maxlength="14"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                />
            </mat-form-field>

            <p class="cls-Captcha-text-prev">Подтвердите что вы не робот.</p>

            <div class="cls-blockCaptcha">
                <!-- <div>
                    <img [src]="captchaJpg" />
                    <mat-icon
                        svgIcon="refresh"
                        (click)="refreshCaptcha()"
                    ></mat-icon>
                </div> -->
                <div class="captcha__right" *ngIf="captcha; ">
                    <div
                        class="captcha__img"
                        [innerHTML]="captcha.svg | safe : 'html'"
                    ></div>

                    <button
                        class="captcha__refresh"
                        type="button"
                        (click)="refreshCaptcha()"
                    >
                        <mat-icon
                            svgIcon="refresh"                          
                        ></mat-icon>
                    </button>
                </div>
                <mat-form-field class="w-100">
                    <input
                        [type]="'text'"
                        id="regcaptha"
                        matInput
                        formControlName="captchaValue"
                        [readonly]="loading"
                        (keydown.space)="$event.preventDefault()"
                        required
                        maxlength="4"
                    />
                    <mat-label>Введите код с картинки</mat-label>
                </mat-form-field>
            </div>

            <button
                class="w-100 btn btn-lg cls-btn cls-btn-reg"
                type="submit"
                [disabled]="loading || !frmRegPhone.valid"
                (click)="regPhone()"
            >
                Продолжить
            </button>

<!--             
            <div class="cls-txt-center cls-footer1">Есть аккаунт?</div>
            <div class="cls-txt-center cls-footer2" (click)="goAuth()" placeholder="Регистрация"><mat-icon class="cls-icon-arrow-right" svgIcon="arrow_left"></mat-icon>Войти</div> -->

        </div>
    </form>

    <div *ngIf="step == 1" class="reg-form reg-form-code">
        <div class="reg-form-code__text-info">
            На +7 {{ phoneNumber }}, отправлен код подтверждения.
        </div>
        <div class="reg-form-code__input">
            <input
                id="fieldCode1"
                type="string"
                [(ngModel)]="codeSMS!.char1"
                (ngModelChange)="onCodeSMSChange()"
                max="1"
                required
                (keydown)="onKeyDownCodeSMS($event)"
            />
            <input
                id="fieldCode2"
                type="string"
                [(ngModel)]="codeSMS!.char2"
                (ngModelChange)="onCodeSMSChange()"
                max="1"
                required
                (keydown)="onKeyDownCodeSMS($event)"
            />
            <input
                id="fieldCode3"
                type="string"
                [(ngModel)]="codeSMS!.char3"
                (ngModelChange)="onCodeSMSChange()"
                max="1"
                required
                (keydown)="onKeyDownCodeSMS($event)"
            />
            <input
                id="fieldCode4"
                type="string"
                [(ngModel)]="codeSMS!.char4"
                (ngModelChange)="onCodeSMSChange()"
                max="1"
                required
                (keydown)="onKeyDownCodeSMS($event)"
            />
        </div>
        <!--
        <button class="w-100 btn btn-lg cls-btn cls-btn-reg" type="submit" (click)="regCodeCMC();">Подтвердить</button>
-->

        <div
            class="cls-footer3 text-center"
            (click)="goReg(3)"
            placeholder="Назад"
            style="padding-bottom: 0px; padding-top: 181px"
        >
            Назад
        </div>
    </div>

    <div *ngIf="step == 2" class="reg-form">
        <div class="reg-form-code__text-info">
            На +7 {{ phoneNumber }}, зарегистрированы
            {{ patients.length }} пользователя. Выберите себя.
        </div>
        <div class="row">
            <div
                class="col-6 block-patient"
                *ngFor="let pat of patients"
                (click)="regSelectPatient(pat)"
            >
                {{ pat.fio_1 }}
                <br />
                {{ pat.fio_2 }}
                <div class="block-patient__ear" *ngIf="pat.ear">
                    {{ pat.ear }}<span> лет</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="step == 3" class="reg-form">
        <app-dog-list
            (onError)="onErrorDog($event)"
            [tmpToken]="tmpToken"
            [dogPREVIEW_REQUIRED]="dogPREVIEW_REQUIRED"
            (onEvent)="onEventDog($event)"
        >
        </app-dog-list>
    </div>

    <div class="cls-footer1 text-center">Первый раз в личном кабинете?</div>
    <div
        class="cls-footer2 text-center"
        (click)="goReg(1)"
        placeholder="Регистрацииия"
    >
        Регистрация
    </div>
</div>
