import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { APP_BASE_HREF } from '@angular/common';
import { lastValueFrom, Observable } from 'rxjs';
import { dateToText } from '../components/application/global.function';
import {
    IAuthPatientPhone,
    IContract,
    ICreatePatient,
    IDogListForSig,
    IDogSignatureRes,
    IPatientExt,
    ITokenAndPatientId,
    IUser,
} from '../interfaces/patient';
import { HttpNewService } from './application/http-new.service';
import { IHttpRequest } from './application/IHttpRes.interface';
import { CookieConsentService } from './cookie-consent-service';
import { EventService } from './event/event.service';
import { Store } from './store.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    // public token: string | null;
    // public patientId: number | null;
    public isAccessConfigNeeded = false;
    public accessConfigArray: string[] = [];

    // /**
    //  * Генерация сообщений об ошибках
    //  * @param error
    //  * @returns {any}
    //  */
    // private static handleError(error: any): any {
    //     let message = '';

    //     if (!error.json().success) {
    //         if (error.json().data.errorCode === 'WrongLoginData') {
    //             message = 'Неверно указаны имя пользователя или пароль';
    //         }
    //         if (error.json().data.errorCode === 'Exception') {
    //             message =
    //                 'Не удается связаться с сервером, повторите попытку позднее';
    //         }
    //     } else {
    //         message = error.data ? error.data : error.toString();
    //     }
    //     return throwError(() => new Error(message));
    // }

    constructor(
        @Inject(APP_BASE_HREF) public appBaseHref: string,
        private router: Router,
        private httpNew: HttpNewService,
        private eventService: EventService,
        private cookieService: CookieConsentService
    ) {
        // const Patient = Store.getCookieData<{
        //     token: string;
        //     patient_id: number;
        // }>();
        // console.log(Patient);
        // if (Patient && Patient.token) {
        //     this.token = Patient.token;
        // } else {
        //     this.token = null;
        // }
        // if (Patient && Patient.patient_id) {
        //     this.patientId = Patient.patient_id;
        // } else {
        //     this.patientId = null;
        // }
    }

    /**
     *
     * @param user
     * @param passwd
     * @returns {Observable<any>}
     */
    public login$(user: string, passwd: string): Promise<IUser> {
        return lastValueFrom(
            this.httpNew.post('/api/login', { login: user, passwd: passwd })
        )
            .then((result) => {
                // console.log(result);
                let tmp = result;
                // if (tmp && tmp.length === 1) {
                //     tmp = tmp[0];
                // }

                if (tmp && tmp.token && tmp.patientid) {
                    let r = Object.assign({
                        token: tmp.token,
                        patientId: tmp.patientid,
                    });
                    if (tmp.ext) {
                        r = Object.assign(r, { ext: tmp.ext });
                    }
                    // console.log(r);
                    return r;
                } else {
                    return Promise.reject(result);
                }
            })
            .catch((err) => {
                // console.log(err);
                return Promise.reject(err);
            })
            .finally(() => {
                this.eventService.emit('auth:login');
            });
    }

    protected redirectTo(uri: string) {
        this.router.navigate([uri]);
    }
    // private emitTokenEvent(data: string) {
    //     const event = new CustomEvent(tokenEventName, { detail: data });
    //     console.log(event);
    // }

    public getAccessConfig(patientId: number): Promise<string[]> {
        return lastValueFrom(
            this.httpNew.get('/api/user/access', { patientId: patientId })
        )
            .then((result) => {
                // console.log(result);
                return result;
            })
            .catch((err) => {
                throw err;
            });
    }

    public reg$(
        patient: ICreatePatient
    ): Observable<{ id: number; err_code: number; err_text: string }> {
        if (patient.birthDate) {
            patient.birthDate = dateToText(new Date(patient.birthDate));
        }

        return this.httpNew.post('/api/patient', patient);
    }

    public loginOk$(
        token: string,
        patientId: number,
        ext: IPatientExt[]
    ): boolean {
        // this.token = token;
        // this.patientId = patientId;
        // Записываем токен в хранилище
        // Store.setData('Patient', { token: token, patientId: patientId });

        if (ext && ext[0]) {
            ext.forEach((item) => {
                Store.setData(item.name, { val: item.value });
            });
        }
        return true;
    }

    // /**
    //  * Проверка авторизован ли пользователь в данный момент
    //  * @returns {boolean}
    //  */
    // public isLogged(): boolean {
    //     return this.token != null && this.patientId != null;
    // }

    /**
     * Выход из системы, осуществляется только при выполненной авторизации
     */
    public logout(): void {
        //
        this.eventService.emit('auth:logout');
        Store.clearAllStore();
        this.cookieService.deleteAll();
        lastValueFrom(this.serverLogout()).finally(() => {
            // if (this.cookieService.checkCookieExists('pa-web_Patient_Local'))
            this.cookieService.deleteAll(this.appBaseHref.replace(/\/$/, ''));
            this.redirectTo('/login');
        });
        // this.token = null;
        // this.patientId = null;
    }

    /**
     * Процедура logout'a на стороне сервера
     * @param token
     * @returns {Observable<boolean>}
     */
    public serverLogout(): Observable<boolean> {
        return this.httpNew.post('/api/logout', null);
    }

    /**
     * Смена пароля
     * @param pOldPassword - Старый пароль
     * @param pNewPassword - Новый пароль
     * @returns {Observable<boolean>}
     */
    public changepw(
        pOldPassword: string,
        pNewPassword: string
    ): Observable<any> {
        return this.httpNew.post('/api/changepw', {
            oldPassword: pOldPassword,
            newPassword: pNewPassword,
        });
    }

    /**
     * Восстановление пароля
     * @param  lastName='' - фамилия
     * @param  firstName='' - имя
     * @param  secondName='' - отчество
     * @param  birthDate='' - дата рождения в формате dd_MM_yyyy
     * @param  email='' - электронная почта
     * @returns {Observable<boolean>}
     */
    public findpatientchangepw(
        pLastName: string,
        pFirstName: string,
        pSecondName: string,
        pBirthDate: string,
        pEmail: string,
        pCaptcha: string,
        pCaptchaSolid: string | undefined
    ): Observable<any> {
        return this.httpNew.post('/api/findpatientchangepw', {
            lastName: pLastName,
            firstName: pFirstName,
            secondName: pSecondName.length < 1 ? null : pSecondName,
            birthDate: pBirthDate,
            email: pEmail,
            captchaValue: pCaptcha,
            captchaId: pCaptchaSolid ? pCaptchaSolid.toString() : null,
        });
    }

    /**
     * Проверка токена на восстановление
     * @param  pToken='' - токен
     * @returns {Observable<boolean>}
     */
    public checktr(pToken: string): Observable<any> {
        return this.httpNew.post('/api/checktr', { token: pToken });
    }

    /**
     * смена пароля пациенту по токену
     * @param  pToken='' - токен
     * @param  pNewpw='' - новый пароль
     * @returns {Observable<boolean>}
     */
    public changepwtoken(pToken: string, pNewpw: string): Observable<any> {
        return this.httpNew.post('/api/changepwtoken', {
            token: pToken,
            newpw: pNewpw,
        });
    }

    /***** Договора  ********/

    /* Список договоров (не подписанных) */
    public getContractList(
        tmp: ITokenAndPatientId
    ): Observable<IDogListForSig[]> {
        //      const url = '/api/patient/' + tmp.patientId + '/contract';  // tomCat
        const url = '/contract/patient/list/sig';
        return this.httpNew.get(url);
    }

    /* Подписать договор */
    public postContract(
        tmp: ITokenAndPatientId,
        templateId: number
    ): Observable<IDogSignatureRes[]> {
        //TomCat      const url = '/api/patient/' + tmp.patientId + '/contract/template/' + templateId;
        /*
            const url = '/contract/template/' + templateId;
            return this.httpNew.post(url , null, tmp.token, true);
    */

        const url = `/contract/sig?patientId=${tmp.patientId}&contractId=${templateId}`;
        return this.httpNew.get(url);
    }

    /* Список подписанных договоров */

    public getContractListToPatient(): Observable<IContract[]> {
        //TomCat      const url = '/api/patient/' + this.patientId + '/contract/sign';
        const url = '/contract/patient/list';
        return this.httpNew.get(url);
    }

    public patientforphone$(
        patient: IAuthPatientPhone,
        captchaId: string | undefined
    ): Observable<IHttpRequest> {
        return new Observable((observer) => {
            return this.httpNew
                .post('/api/patientforphone', { ...patient, captchaId }, true)
                .subscribe(
                    (result) => {
                        observer.next(result);
                    },
                    (err) => {
                        observer.error(err);
                    }
                );
        });
    }

    public loginphone$(p_patientid: number): Observable<boolean> | any {
        let param = {
            patientid: p_patientid,
            // captchaValue: p_captcha,
            // captchaId: p_captchaSolid.toString(),
        };
        return new Observable((observer) => {
            return this.httpNew.post('/api/loginphone', param).subscribe(
                (result) => {
                    if (result && result.token && result.patientid) {
                        let tmp = result;
                        if (tmp && tmp.token && tmp.patientid) {
                            let r = Object.assign({
                                token: tmp.token,
                                patientId: tmp.patientid,
                            });
                            if (tmp.ext) {
                                r = Object.assign(r, { ext: tmp.ext });
                            }
                            observer.next(r);
                        } else {
                            observer.error(false);
                        }
                    } else {
                        observer.error(false);
                    }
                },
                (err) => {
                    observer.error(err);
                }
            );
        });
    }
}
