import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    IInetuserLog,
    IInetuserLogSize,
    IPatient,
    TPayContract,
} from '../interfaces/patient';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { EventService } from './event/event.service';
@Injectable({
    providedIn: 'root',
})
export class PatientService {
    firstname = '';
    lastname = '';
    secondname = '';
    birthdate = '';

    constructor(
        private httpNew: HttpNewService,
        private auth: AuthService,
        private eventService: EventService
    ) {
        this.getServerPatientInfo$().subscribe((res) => {
            this.firstname = res.firstname;
            this.lastname = res.lastname;
            this.secondname = res.secondname;
            this.birthdate = res.birthdatestr;
        });

        eventService.on('auth:logout', this.clearPatientInfo);
    }

    public getServerPatientInfo$(
        token?: string,
        isFamilyAccount?: boolean
    ): Observable<IPatient> {
        return this.httpNew.get(`/patient/info`, {
            token: token || null,
            isFamilyAccount: isFamilyAccount || false,
        });
    }

    public get getFullName(): string {
        return [this.lastname, this.firstname, this.secondname].join(' ');
    }

    public get getBirthdate(): string {
        return this.birthdate;
    }

    /* Смена пароля*/
    public changepasswd$(
        pOldPassword: string,
        pNewPassword: string
    ): Observable<any> {
        return this.httpNew.post(`/api/changepasswd`, {
            oldPassword: pOldPassword,
            newPassword: pNewPassword,
        });
    }

    /* Количество активности пользователя*/
    public getInetuserLogSize$(): Observable<IInetuserLogSize> {
        // TomCat    return this.httpNew.get('/api/log/size' , null);
        return this.httpNew.get('/log/size');
    }
    // /api/log/size

    /* Список активности пользователя*/
    public getInetuserLog$(
        pStart?: number,
        pEnd?: number
    ): Observable<IInetuserLog[]> {
        let url = '/log/rec';
        if (pStart && pEnd) {
            url = url + `?start=${pStart}&end=${pEnd}`;
        }
        return this.httpNew.get(url);
    }

    // Список параметров заявлений
    public getStatementType$(): Observable<any> {
        return this.httpNew.get(`/statement/params`);
    }

    /*
  // Список параметров заявлений
  public getStatementParam$(pTypeId: number): Observable<IStatementParam[]> {
//    return this.httpNew.get(`/api/patient/${null}/statement/type` , null)
    return this.httpNew.get(`/api/patient/${null}/statement/type/${pTypeId}/param` , null)
  }
*/

    // Запись параметров, формирование заявления и его отправка
    public getStatementSave$(pTypeId: number, pDate: string): Observable<any> {
        return this.httpNew.post(`/statement/create`, {
            statement_params: pDate,
        });
    }

    public create_pw$(): Observable<any> {
        return this.httpNew.get(`/patient/create_pw`, null);
    }

    public clearPatientInfo(): void {
        this.lastname = '';
        this.secondname = '';
        this.firstname = '';
        this.birthdate = '';
    }
    // договоры

    public getPayContract(): Observable<TPayContract[]> {
        //TomCat      const url = '/api/patient/' + this.patientId + '/contract/sign';
        const url = '/contract/paycontract';
        return this.httpNew.get('/contract/paycontract', null);
    }

    public getSms(
        text: string = 'Код подтверждения: {{code}} Подтверждение договора на оказание платных медицинских услуг. Не сообщайте никому!'
    ): Observable<Record<string, any>> {
        return this.httpNew.get('/contract/paycontract-sendsms', {
            text,
        });
    }

    public getContractConfirm(
        contractId: number,
        code: string,
        messageId: number
    ): Observable<any> {
        return this.httpNew.get('/contract/paycontract-confirm', {
            code,
            messageId,
            contractId,
        });
    }

    private patientNameSubject = new BehaviorSubject<string>('');
    patientName$ = this.patientNameSubject.asObservable();

    setPatientName(patientName: string) {
        this.patientNameSubject.next(patientName);
    }
}
