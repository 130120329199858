import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    UntypedFormBuilder,
} from '@angular/forms';
import { showOpacityAnimation } from '../../../animations/show-left-right.animation';
import { AuthService } from '../../../services/auth.service';
import { IPatient, ITokenAndPatientId } from '../../../interfaces/patient';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../services/application/config.service.';
import { LoadService } from '../../../services/application/load.service';
import { Title } from '@angular/platform-browser';
import { EsiaService } from '../../../services/esia.service';
import { CookieConsentService } from 'src/app/services/cookie-consent-service';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/lib/ariadna/alert';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    animations: [showOpacityAnimation],
})
export class AuthComponent implements OnInit {
    @Input() AccessReg: boolean = false;
    animState = 'on';
    loading = false; // Загрузка
    hidePassword = true; // Показывать пароль
    isRecovery = false; // Возможность восстанавления пароля
    frm!: FormGroup;
    getRolesConfig = false; // Нужно ли получать информацию о доступных разделах пользователю при логине
    public tmpToken: ITokenAndPatientId | null;
    public tokens: string | null;
    public dogPREVIEW_REQUIRED: boolean = false; // Обязательность предпросмотра
    public error = '';
    public showDoglist = false;
    public captchaJpg = ''; // картинка дла КАПЧИ
    //  public dogHoverId: number = 0;  // Наведенный договор, если нет то = 0
    private _setting: any; // Настройки из файла
    public patient: IPatient | null = null; // Авторизированный пациент
    private paramEmit = 1; // 1- Регистрация, 3- по телефону
    public Auth_type = 0; // Тип авторизации
    public thisTest = false; // Показывать подсказки при логировании

    /*
        0 - Только по логину и  паролю из карты пациента
        1 - Только по телефону (МНТК)
        2 - Вход по логину и паролю с возможностью авторизации по телефону
  */

    private esia_state: string | null = null; // ГосУслуги-код (при переходе с ГосУслуг)
    public esia_link: string | null = null; // доступность кнопки Авторизациии через ГосУслуги

    get username() {
        // @ts-ignore
        return this.frm.get('username').value;
    }

    get password() {
        // @ts-ignore
        return this.frm.get('password').value;
    }

    @Output() onChanged = new EventEmitter<number>(); // выбран
    @Output() onError = new EventEmitter<string>(); // Ошибка
    @Output() onSuccess = new EventEmitter<string>();

    constructor(
        private auth: AuthService,
        private router: Router,
        private title: Title,
        private configS: ConfigService,
        private load: LoadService,
        private activateRoute: ActivatedRoute,
        private esia: EsiaService,
        private cookieService: CookieConsentService,
        private alertService: AlertService
    ) {
        this.tmpToken = null;
        this.tokens = null;
        this._setting = load.configData.pages.login;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }

        if (this._setting && this._setting.DogPREVIEW_REQUIRED) {
            this.dogPREVIEW_REQUIRED = this._setting.DogPREVIEW_REQUIRED;
        }
        if (this._setting?.isRolesSystem) {
            this.getRolesConfig = true;
        }

        // Проверка на тип авторизации
        if (this._setting.Auth_type && this._setting.Auth_type > 0) {
            this.Auth_type = this._setting.Auth_type;
        }

        if (
            load.configData.pages.recovery &&
            load.configData.pages.recovery.WEB_LK_RECOVERY_TO_EMAIL
        ) {
            this.isRecovery =
                load.configData.pages.recovery.WEB_LK_RECOVERY_TO_EMAIL == 1;
        }

        if (this._setting && this._setting.Auth_ThisTest) {
            this.thisTest = true;
        }
        // Проверка на возможность входа через ГосУслуги.
        lastValueFrom(this.esia.getInfo$())
            .then((result: string) => {
                if (result) {
                    this.esia_link = result;
                }
            })
            .catch((error: any) => {
                /* Обработку ошибок не нужно
                    if (error.error && error.error.data && error.error.data.errorMsg) {
          //            this.errText = error.error.data.errorMsg;
                      this.onError.emit(error.error.data.errorMsg);
                    } else {
                      this.onError.emit('Неверный логин или пароль');
                    }
          */
                return null;
            });
    }

    ngOnInit(): void {
        const tokenFromStorage = sessionStorage.getItem(
            'sessionTokenAuthAriadna'
        );

        if (!tokenFromStorage) {
            const token = Array(32)
                .fill('')
                .map(() =>
                    String.fromCharCode(
                        48 + Math.floor(Math.random() * (10 + 26 + 26)) - 10
                    )
                )
                .join('');
            sessionStorage.setItem('sessionTokenAuthAriadna', token);
        }
        // Проверка на state, переход с Госуслуг
        if (
            this.activateRoute.snapshot.queryParams &&
            this.activateRoute.snapshot.queryParams.state
        ) {
            this.esia_state = this.activateRoute.snapshot.queryParams.state;
            // this.cookieConsentSerive.acceptAllCookies();
            this.loading = true;
            this.esia.getInfoState$(this.esia_state!).subscribe(
                (res: any) => {
                    this.loading = false;
                    this.tmpToken = {
                        token: res.token,
                        patientId: res.patientId,
                    };
                    if (res.ext) {
                        this.tmpToken.ext = res.ext;
                    }
                    this.cookieService.acceptAllCookies();
                    this.setCookies(res.token);
                    this.loginOK();
                    this.showDoglist = true;
                },
                (err: any) => {
                    this.alertService.error(err.msg);
                    this.onError.emit(err.msg);
                    this.loading = false;
                }
            );
        } else {
            if (
                this.activateRoute.snapshot.queryParams &&
                this.activateRoute.snapshot.queryParams.ERR
            ) {
                this.onError.emit('Ошибка получения данных от ГосУслуг.');
            }
            this.esia_state = null;
            this.loading = false;
        }
        console.log(this.Auth_type);
        if (this.Auth_type === 1) {
            // Только по телефону
            this.goReg(3);
            this.initialize();
        } else {
            this.initialize();
        }
    }

    protected redirectTo(uri: string) {
        this.router.navigate([uri]);
    }

    private async setAuthConfig(): Promise<void> {
        if (this.getRolesConfig && this.tmpToken?.patientId) {
            const res = await this.auth.getAccessConfig(
                this.tmpToken.patientId
            );
            if (res.length <= 0) {
                res.push(
                    'lk_access_appointment',
                    'lk_access_patient_data',
                    'lk_access_see_services'
                );
            }
            this.load.setAccessUserConfig(res);
            // Надо будет доработать для обновления страницы юзера в случае несовпадений конфигов(Платная недоработка).
            // setInterval(() => {
            //     this.setAuthConfig();
            //     if (res !== this.load.getAccessUserConfig) {
            //         window.location.reload();
            //     }
            // }, 60 * 1000 * 60 * 1);
        }
    }
    private initialize(): void {
        this.frm = new FormGroup({
            username: new FormControl('', [
                Validators.required,
                Validators.minLength(1),
            ]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(1),
            ]),
        });
    }

    public loginOK(): void {
        this.auth.loginOk$(
            this.tmpToken!.token,
            this.tmpToken!.patientId,
            this.tmpToken!.ext!
        );
        this.redirectTo('/home');
    }

    public onErrorDog(ev: string = ''): void {
        this.onError.emit(ev);
    }

    public onEventDog(ev: string = ''): void {
        //
        switch (ev) {
            case 'LOGINOK': {
                this.loginOK();
                break;
            }
            case 'LOGOUT': {
                this.logout();
                break;
            }
        }
    }

    /* Назад */
    logout(): void {
        this.auth.logout();
        this.showDoglist = false;
    }
    private setCookies(token: string): void {
        if (!document.cookie.includes('cookieconsent_status')) {
            this.cookieService.setCookie('cookieconsent_status', 'true');
        }
        this.cookieService.setCookie('pa-web_Patient_Local', token);
    }

    async login(): Promise<void> {
        this.loading = true;
        this.tmpToken = null;
        this.onError.emit('');
        this.onSuccess.emit('');

        try {
            this.cookieService.acceptAllCookies();
            const result = await this.auth.login$(this.username, this.password);
            // this.setCookies(result.token!);

            if (result.token && result.patientId) {
                this.tokens = result.token;
                this.tmpToken = {
                    token: result.token,
                    patientId: result.patientId,
                };
                await this.setAuthConfig();

                this.showDoglist = true;
            }
            // this.redirectTo('/home');
        } catch (error: any) {
            // console.log(error);
            if (error.msg) {
                this.onError.emit(error.msg);
            } else {
                this.onError.emit('Неверный логин или пароль');
            }
        } finally {
            this.loading = false;
        }
    }

    loginEsia(): void {
        this.loading = true;
        if (this.esia_link) {
            window.location.href = this.esia_link;
        }
    }

    whenAnimate(event: any) {
        //
        if (event.toState === 'off') {
            //
            this.onChanged.emit(this.paramEmit);
        }
    }

    goReg(pVal: number): void {
        this.paramEmit = pVal;
        this.animState = 'off';
    }

    // Обновление картинки КАПЧИ
    // public refreshCaptcha() {
    //     this.captchaJpg =
    //         this.configS.getValue('hostBackend') +
    //         '/api/captcha.jpg?' +
    //         new Date().getTime();
    //     // @ts-ignore
    //     if (this.frmReg && this.frmReg.controls) {
    //         // @ts-ignore
    //         this.frmReg.controls['captcha'].reset('');
    //     }
    // }
}
