<div class="my-full-screen-dialog">
    <div mat-dialog-title class="cls-modal-blok-title last-events-block-div">
        <div class="title-spec" class="last-events--title">Вы были у врача</div>
        <mat-icon [mat-dialog-close]="true">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.77 0L12 7.77L4.23 0L0 4.23L7.77 12L0 19.77L4.23 24L12 16.23L19.77 24L24 19.77L16.23 12L24 4.23L19.77 0Z"
                    fill="#E6E6E6"
                />
            </svg>
        </mat-icon>

        <!--
    <span class="cls-modal-blok-header">Описание посещения пациента</span>
  -->
    </div>
    <div *ngIf="!isLoading; else loader" class="modal__content">
        <div class="last-events">
            <div class="last-events--time">
                <!-- тут время посещения и день недели-->
                <span class="time">{{ getTalonDateTime(historyDat) }}</span>
                <p class="text">{{ getNameDay(historyDat) }}</p>
            </div>
            <div class="last-events--doc">
                <!--инфа о доке-->

                <div class="last-events--doc-imgWrap">
                    <img
                        [src]="getImgSrcDoc(doctorid)"
                        (error)="errorHandlerIMG($event, 'doc')"
                        alt="изо доктора"
                    />
                </div>
                <div class="last-events--doc-text">
                    <div class="name">
                        {{ getFIODoc(historyName) }}
                    </div>
                    <div class="prof">
                        {{ historySpec }}
                    </div>
                </div>
            </div>
            <div class="last-events--aboutDoc">
                <div class="wrap">
                    <p class="grey">Исследование</p>
                    <p class="blue">
                        {{ historyTypetext }}
                    </p>
                </div>
                <div class="wrap">
                    <p class="grey">Отделение</p>
                    <p class="blue">
                        {{ historyDepname }}
                    </p>
                </div>
                <div class="wrap">
                    <p class="grey">Повторная запись</p>
                    <p class="blue">
                        <span [innerHTML]="txtPhone" class="txtPhone"> </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="last-events--table-wrap" mat-dialog-content>
            <div class="last-events--conclusion">
                <div class="result-panel">
                    <button
                        (click)="gotToDownload(historyId, historyType, $event)"
                        class="last-events--download result-panel__conclusion"
                    >
                        <span>Заключение</span>
                        <img
                            *ngIf="pdfLoading"
                            class="loader-rotate"
                            src="./assets/img/svg/loader-btn.svg"
                        />
                        <img
                            *ngIf="!pdfLoading"
                            src="./assets/img/svg/pdf-download-mobile.svg"
                        />
                    </button>
                    <a
                        class="result-panel__dicom-download result-panel__button"
                        href="{{ getDownloadUrl() }}"
                        download="{{ 'dicom-' + accessionNumber + '.zip' }}"
                        *ngIf="accessionNumber"
                    >
                        <img src="./assets/img/svg/dicom-download.svg" />
                    </a>
                    <button
                        class="result-panel__dicom-view result-panel__button"
                        (click)="openDicomModal($event)"
                        *ngIf="accessionNumber"
                    >
                        <img src="./assets/img/svg/dicom-view.svg" />
                    </button>
                </div>
                <ng-template #pdfModal let-modal>
                    <div class="modal_documents">
                        <div class="header">
                            <span class="header_text">Документы</span>
                            <button type="button" class="btn-close" aria-label="Закрыть" (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <ul>
                            <li *ngFor="let item of pdf | keyvalue, index as i">
                                <button class="result-item" (mouseup)="pdfToDownload($event, item)" (mouseover)="mouseEnt($event)" (mouseleave)="mouseOut($event)">
                                    <img
                                    *ngIf="!pdfLoading"
                                    class="result-item__img"
                                    [src]="pdfImageURL" />
                                Документ по посещению {{i+1}} 
                                </button>
                            </li>
                        </ul>

                        <div class="modal_close_button">
                            <button class="btn btn-dialog-close" (click)="modal.dismiss('Cross click')">
                                Закрыть
                            </button>
                        </div>
                    </div>
                </ng-template>
                <div
                    *ngIf="resHtml"
                    class="last-events--table"
                    [innerHTML]="resHtml"
                ></div>
            </div>
            <div class="last-events--btnWrap">
                <button class="btn btn-dialog-close" [mat-dialog-close]="true">
                    Закрыть
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #loader>
    <loader type="page"></loader>
</ng-template>
