import { ChipOption } from './chip';
import { SelectOption } from './select';
import {
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';

/**
 * Способ ввода данных в протокол (приходит с базы)
 * */
export enum ProtocolItemInputType {
    string, //0  - Простой текст (строка),
    textarea, //1  - Описание (много строк),
    select, //2  - Значения из списка,
    ref_select, //3  - Значения из внешнего справочника,
    formula, //4  - Формула,
    table, //5  - Таблица,
    sys_ref, //6  - Значение из системного справочника,
    sql, //7  - Формула SQL,
    diagnos_field, //8  - Поле для ввода диагноза,
    services_field, //9  - Поле для ввода услуг,
    tree, //10 - Значение из дерева,
    multi_select, //11 - Значение из списка отмеченное галочками,
    file, //12 - Файл,
    schema, //13 - Схема (изображение);
}

/**
 * Формат хранения value в базе
 * */
export enum ProtocolItemDataType {
    string, //0-Текст,
    date, //1-Дата,
    time, //2-Время,
    datetime, //3-Дата и время,
    bool, //4-Логический,
    float, //5-Число с плавающей точкой,
    id, //6-Идентификатор,
    number, //7-Целое число,
    text, //8-Большой текст (clob)
}

/**
 * Разделитель, не разделитель
 */
export enum ProtocolItemType {
    delimeter, //0 - Разделитель
    editable, //1 - Рeдактируемый
}

export interface ProtocolInfo {
    is_exists: number;
    is_paid: number | null;
    is_true_pat: number;
    visit_dat: string;
    visit_time: string;
}

/**
 * Приходящий с базы
 */
export interface ProtocolItem {
    value_type: ProtocolItemInputType;
    typ: ProtocolItemType;
    data_type: ProtocolItemDataType;
    form_id: number;
    form_name: string;
    form_result_id: number;
    form_item_id: number;
    form_item_val_id: number | null;
    form_item_val_text: string | null;
    form_result_value_id: number | null;
    form_result_value_text: string | null;
    form_value_id: number | null;
    is_list_chkr: number | null;
    is_multi: number | null;
    is_required: number | null;
    question_name: string | null;
}

/**
 * Тип поддерживаемых инпутов в протоколе
 */
export type ProtocolControlType =
    | 'text'
    | 'select'
    | 'multi-select'
    | 'delimiter'
    | 'date';

/**
 * Тип для группировки записей из базы по id инпута
 */
export type ProtocolItemsGroup = [number, ProtocolItem[]];

/**
 * Конструктор протокола. Принимает записи с базы, возврадает на их основании новый протокол.
 */
export const ProtocolBuilder = (items: ProtocolItem[]) => {
    const formItemIdMap = new Map<number, ProtocolItem[]>();
    items.reduce((map, item) => {
        const formItem = map.get(item.form_item_id);

        if (formItem) formItem.push(item);
        else map.set(item.form_item_id, [item]);

        return map;
    }, formItemIdMap);

    const protocolItemsGroup = Array.from(formItemIdMap);

    const controls = protocolItemsGroup.map(
        (item: ProtocolItemsGroup) => new ProtocolControl(item)
    );

    return new Protocol(controls);
};

/**
 * Основной класс для работы с протоколами
 * Создаётся при помощи ProtocolBuilder
 */
export class Protocol {
    controls: ProtocolControl[];
    form: FormGroup = new FormGroup({});

    constructor(controls: ProtocolControl[]) {
        this.controls = controls;
        this.initForm(controls);
    }

    /**
     * Инициализация формы
     */
    private initForm(controls: ProtocolControl[]) {
        controls.forEach((control) => {
            const validators: ValidatorFn[] = [];

            if (control.isRequired) validators.push(Validators.required);

            this.addFormControl(control, validators);
        });
    }

    /**
     * Добавляет новый FormControl в поле form. Можно передать массив валидаций
     * @param control  Поле протокола
     * @param validators Валидаторы поля
     */
    addFormControl(
        control: ProtocolControl,
        validators: ValidatorFn[] = []
    ): void {
        this.form.addControl(
            control.name,
            new FormControl(control.defaultValue, [...validators])
        );
        this.form.get('path');
    }

    /**
     * Получение массива записей с параметрами для сохранения протокола в БД
     */
    prepareToSave(visitID: number) {
        return this.controls.map((control) => {
            let valuesText = ''; // Значение / значения ('груша;яблоко')
            let valuesId = ''; // id / ids значений  ('1;3')
            let valueText = ''; // Отображаемое значение  (Груша, яблоко)

            const formControl = this.form.get(control.id.toString());

            if (control.type === 'multi-select') {
                const selected = formControl?.value.filter(
                    (item: ChipOption) => item.selected
                );

                valuesText =
                    selected?.map((item: ChipOption) => item.text).join(';') ??
                    '';
                valuesId =
                    selected?.map((item: ChipOption) => item.value).join(';') ??
                    '';
                valueText = valuesText.replace(';', ', ');
            } else if (control.type === 'select') {
                if (formControl?.value) {
                    valuesText = formControl?.value.text ?? '';
                    valuesId = formControl?.value.value.toString() ?? '';
                    valueText = formControl?.value.text ?? '';
                }
            } else if (control.type === 'text') {
                valueText = formControl?.value;
            } else if (control.type === 'date') {
                valueText = formControl?.value;
            }

            if (valueText === null) valueText = '';
            if (valuesId === null) valuesId = '';
            if (valuesText === null) valuesText = '';

            return {
                p_visit_id: +visitID,
                p_form_result_id: +control.item.form_result_id,
                p_form_item_id: +control.item.form_item_id,
                p_values_text: valuesText,
                p_values_id: valuesId,
                p_text: valueText,
            };
        });
    }
}

/**
 * Поле/контрол протокола
 */
export class ProtocolControl {
    base: ProtocolItemsGroup; // Содержит все записи из базы по протоколу с одинаковым form_item_id
    id: number;
    name: string;
    label?: string;
    isRequired: boolean;
    options?: ChipOption[] | SelectOption[]; // Опшены для списков
    isMultiSelect: boolean;
    item: ProtocolItem;
    defaultValue: any = '';
    data_type: number;

    constructor(base: ProtocolItemsGroup) {
        this.base = base;
        const [id, items] = base;
        const item = items[0];

        // Присвоение общей информации об инпуте
        this.item = item;
        this.id = id;
        this.name = this.id.toString();
        this.label = item.question_name ?? undefined;
        this.isRequired = !!item.is_required ?? false;
        this.isMultiSelect = !!item.is_multi ?? false;
        this.data_type = item.data_type;

        // Присвоение дефолтного value
        if (this.item.value_type === ProtocolItemInputType.multi_select) {
            this.options = this.initChips(items);

            this.defaultValue = this.options.filter((opt) => opt.selected);
        } else if (this.item.value_type === ProtocolItemInputType.select) {
            this.options = this.initOptions(items);

            this.defaultValue = this.options.find((opt) => opt.selected);
        } else if (
            this.item.value_type === ProtocolItemInputType.string ||
            this.item.value_type === ProtocolItemInputType.ref_select
        ) {
            this.defaultValue = this.item.form_result_value_text;
        }
    }

    /**
     * Формирует массив опшенов на основании записей из базы
     */
    initOptions(items: ProtocolItem[]) {
        return items.map((item) => ({
            value: item.form_item_val_id ?? '',
            text: item.form_item_val_text ?? '',
            selected: item.form_item_val_id === item.form_value_id,
        }));
    }

    /**
     * Формирует массив опшенов на основании записей из базы
     */
    initChips(items: ProtocolItem[]) {
        const valueId = new Map();
        items.forEach((item) => {
            if (!valueId.has(item.form_value_id))
                valueId.set(item.form_value_id, item);
        });

        const itemId = new Map();

        items.forEach((item) => {
            if (!itemId.has(item.form_item_val_id))
                itemId.set(item.form_item_val_id, item);
        });

        const options: ChipOption[] = [];

        Array.from(itemId).forEach(([id, item]) => {
            if (valueId.has(id)) item.selected = true;

            options.push({
                value: item.form_item_val_id ?? '',
                text: item.form_item_val_text ?? '',
                selected: item.selected ?? false,
            });
        });

        return options;
    }

    /**
     * Получение типа контрола
     */
    get type(): ProtocolControlType {
        if (this.item.typ === ProtocolItemType.delimeter) return 'delimiter';

        if (this.item.data_type === ProtocolItemDataType.date) return 'date';

        switch (this.item.value_type) {
            case ProtocolItemInputType.select:
                return 'select';
            case ProtocolItemInputType.multi_select:
                return 'multi-select';
            case ProtocolItemInputType.string:
            default:
                return 'text';
        }
    }
}
