import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service.';
export const tokenEventName = 'getAuth:token';
@Injectable({
    providedIn: 'root',
})
export class HttpNewService {
    constructor(
        private http: HttpClient,
        private configS: ConfigService,
        private router: Router
    ) {}

    private createHeaders(isFile: boolean): HttpHeaders {
        let headers = new HttpHeaders();
        // const token = this.jwtToken;
        const sessionTokenAuth = sessionStorage.getItem(
            'sessionTokenAuthAriadna'
        )!;
        headers = headers.set(
            'sessionToken',
            sessionTokenAuth ? sessionTokenAuth : 'tokenExp'
        );
        // headers = headers.set('Origin', this.configS.getValue('hostBackend'));
        headers = headers.set('X-Requested-With', 'XMLHttpRequest');
        // console.log(headers);
        if (isFile)
            headers = headers.delete('Content-Type', 'application/json');
        // headers = headers.set('Authorization', 'TOKEN ' + token);
        return headers;
    }

    public get(
        url: string,
        queryParams?: Record<string, any> | null,
        isArrayBuffer?: boolean
    ): Observable<any> {
        return this.request('GET', url, queryParams, isArrayBuffer);
    }

    private request(
        method: string,
        url: string,
        queryParams?: Record<string, any> | null,
        isArrayBuffer: boolean = false
    ): Observable<any> {
        const queryOptions: Record<string, any> = {
            headers: this.createHeaders(isArrayBuffer),
            withCredentials: true,
        };

        if (isArrayBuffer) queryOptions.responseType = 'arraybuffer';

        let requestUrl = this.configS.getValue('hostBackend') + url;

        if (queryParams) {
            const keys = Object.keys(queryParams);
            const queryStrings = keys.map((key) => {
                if (queryParams[key] === null || queryParams[key] === undefined)
                    return;
                return `${key}=${queryParams[key]}`;
            });

            requestUrl = requestUrl + `?${queryStrings.join('&')}`;
        }

        return this.http.request(method, requestUrl, queryOptions);
    }

    public put(
        url: string,
        data: {},
        isFile: boolean = false
    ): Observable<any> {
        return this.http.put(this.configS.getValue('hostBackend') + url, data, {
            headers: this.createHeaders(isFile),
            withCredentials: true,
        });
    }

    public delete(url: string, data: Record<string, any>): Observable<any> {
        return this.http.delete(this.configS.getValue('hostBackend') + url, {
            headers: this.createHeaders(false),
            body: data,
            withCredentials: true,
        });
    }
    //TODO: legacy hell, delete and use get method
    public getALL(url: string, token: string | null): Observable<any> {
        let headerss = new HttpHeaders();
        if (token && token.length > 10) {
            headerss = headerss.set('Authorization', 'TOKEN ' + token);
        }
        headerss = headerss.set('Content-Type', 'application/json');
        return this.http.get(this.configS.getValue('hostBackend') + url, {
            headers: headerss,
            withCredentials: true,
        });
    }

    public post(
        url: string,
        data: {} | FormData | null,
        isFile = false
    ): Observable<any> {
        return this.http.post(
            this.configS.getValue('hostBackend') + url,
            data,
            {
                headers: this.createHeaders(isFile),
                withCredentials: true,
                reportProgress: true,
            }
        );
    }
}
