import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dateToText } from '../components/application/global.function';
import {
    IPayment,
    IPaymentInfo,
    IPaySystemInfo,
    IRnumbInfoByPayment,
} from '../interfaces/payment.interface';
import { IServiceSize } from '../interfaces/services';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {
    constructor(private httpNew: HttpNewService, private auth: AuthService) {}
    /*
     * @apiParam {Number} typePay  Тип платежа (22 - аванс)
     * @apiParam {Number} amount  Сумма
     * @apiParam {String} email  Почта
     * @apiParam {String} phone  Телефон
     * @apiParam {String} police Полис = ''
     */
    // /payments/patient/temp
    // Тип платежной системы
    public getPayTemp(
        typePay: Number,
        amount: Number | null,
        email: String | null,
        phone: String | null,
        police: String | null
    ): Observable<any> {
        const param = new Array();

        param.push('typePay=' + typePay);
        param.push('amount=' + amount);

        if (email != null) {
            param.push('email=' + email);
        }
        if (phone != null) {
            param.push('phone=' + phone);
        }
        if (police != null) {
            param.push('police=' + police);
        }

        const pr = param.join('&');
        //TomCat    return this.httpNew.get(`/api/service/patient/${null}/payments?${pr}`, null);
        const url = '/payments/patient/temp?' + pr;
        return this.httpNew.get(url);
    }

    // Оплата
    public getPayOrder(orderid: number): Observable<any> {
        return this.httpNew.post(`/pay/order`, { orderid: orderid });
    }

    // Тип платежной системы
    public getPaySystem(): Observable<IPaySystemInfo> {
        return this.httpNew.get(`/pay/paysystem`);
    }

    // Список платежей по пациенту
    public getPayments(
        pBeginDate: Date | null,
        pEndDate: Date | null,
        pStart?: number,
        pEnd?: number
        // orderBy: string | null = 'acs'
    ): Observable<IPayment[]> {
        const param = new Array();
        if (pBeginDate != null) {
            param.push('beginDate=' + dateToText(pBeginDate));
        }
        if (pEndDate != null) {
            param.push('endDate=' + dateToText(pEndDate));
        }

        if (pStart && pEnd) {
            param.push('start=' + pStart);
            param.push('end=' + pEnd);
        }

        // if (orderBy) {
        //     param.push('orderBy=' + orderBy);
        // }

        const pr = param.join('&');

        const url = '/payments/patient?' + pr;

        const res = this.httpNew.get(url);

        return res;
    }

    /**
     * Получить количество записей
     */

    public getPaymentsSize(
        beginDate: Date | null,
        endDate: Date | null
    ): Observable<IServiceSize> {
        const param = new Array();

        if (beginDate) {
            param.push('beginDate=' + dateToText(new Date(beginDate)));
        }

        if (endDate) {
            param.push('endDate=' + dateToText(new Date(endDate)));
        }

        const pr = param.join('&');
        const url = '/payments/patient/size?' + pr;
        return this.httpNew.get(url);
    }

    // Информация о платеже
    public getPayment(paymentId: number): Observable<IPaymentInfo[]> {
        return this.httpNew.get(`/api/service/payment/${paymentId}`);
    }

    /* Получение информации о платеже из талона */
    public getRnumbInfoByPayment(
        paymentId: number
    ): Observable<IRnumbInfoByPayment[]> {
        return this.httpNew.get(`/api/payment/${paymentId}/rnumb/info`);
    }

    /* Получение баланса по пациенту */
    public getBalanse(): Observable<any> {
        return this.httpNew.get(`/payments/patient/balanse`);
    }
}
