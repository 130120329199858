import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';

const CONFIG_FILE_NAME = isDevMode() ? 'config.local.json' : 'config.json'

@Injectable()
export class ConfigService {
    private configuration: any = {};

    constructor(private httpClient: HttpClient) {
        //        this.configuration = {hostBackend: 'http://10.0.0.204:8080/pa-web', test: 3434};
    }

    loadConfig(): Observable<any> {
        let headerss = new HttpHeaders();
        headerss = headerss.set('Content-Type', 'application/json');
        //

        return new Observable((observer) => {
            this.httpClient
                .get(`./assets/${CONFIG_FILE_NAME}`, { headers: headerss }) // .map((value: any) => {return value.data});
                .subscribe(
                    (result) => {
                        //
                        this.configuration = result;
                        observer.next(result);
                        observer.complete();
                    },
                    (err) => {
                        observer.error(err);
                    }
                );
        });
    }

    getValue(key: string, defaultValue?: any): any {
      
        //
        
        return this.configuration[key] ;
        //        hostBackend: "http://10.0.0.204:8080/pa-web"
        //        return 'http://10.0.0.204:8080/pa-web';
    }
}
