import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TPayContract } from 'src/app/interfaces/patient';
import { IDocuments } from 'src/app/interfaces/document.interface';
import { PatientService } from 'src/app/services/patient.service';
import { ConfigService } from 'src/app/services/application/config.service.';
import { AlertService } from 'src/lib/ariadna/alert';
import { lastValueFrom } from 'rxjs';
// import { IPatient } from 'src/app/interfaces/patient';
@Component({
    selector: 'app-sms',
    templateUrl: './sms.component.html',
    styleUrls: ['./sms.component.scss'],
})
export class SmsComponent implements OnInit {
    @Output() isSmsSend = new EventEmitter<boolean>(false);

    @Input() mobilePhone: string = '';
    private contractId: number | null = null;
    private messageId: number | null = null;
    private code: string = '';
    private smsText: string = '';
    countdown: number = 120;
    timer: ReturnType<typeof setTimeout> | null = null;

    payContracts: TPayContract[] = [];
    contractsPayCountRec = 0;
    pin: string[] = ['', '', '', '', '', ''];
    signingDoc: boolean = false;
    loadingDocs = false;
    docCountRec = 0;
    documents: IDocuments[] = [];
    loadingContracts = false;
    loading = false; // Загрузка
    sms: string = '';
    contractName: string | undefined = '';
    isButtonDisabled: boolean = false;
    isSubmitBtnDisabled: boolean = false;
    selectedContract: number | null = null;
    // loadingSms: boolean = false;

    constructor(
        private ps: PatientService,
        private configS: ConfigService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.getPayContractList();
        this.contractsPayCountRec = 0;
        this.smsText = this.configS.getValue('sms_text');
    }

    get maskedPhone(): string {
        if (!this.mobilePhone) return '';
        const digitsOnly = this.mobilePhone.replace(/\D/g, '');
        return `+7(${digitsOnly.slice(1, 4)})***-**-${digitsOnly.slice(9, 12)}`;
    }

    private startTimer(): void {
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0 && this.timer !== null) {
                clearInterval(this.timer);
                this.countdown = 120;
                this.isButtonDisabled = false;
            }
        }, 1000);
        this.isButtonDisabled = true;
    }
    clearForm(): void {
        this.pin = ['', '', '', '', '', ''];
        for (let i = 0; i < 5; i++) {
            const input = document.getElementById(
                `pin-input-${i}`
            ) as HTMLInputElement;
            if (input) {
                input.value = ''; // Очищаем значение каждого поля
            }
        }
    }

    moveToNext(inputIndex: number, e: Event): void {
        const key = e as KeyboardEvent;

        const input = e.target as HTMLInputElement;
        const regex = /^[0-9]$/;

        if (key.key === 'Backspace') {
            // console.log(123);

            if (inputIndex > 0 && input.value === '') {
                const prevInput = document.getElementById(
                    `pin-input-${inputIndex - 1}`
                ) as HTMLInputElement;
                if (prevInput) {
                    prevInput.focus();
                }
            }
        } else if (regex.test(input.value)) {
            if (inputIndex < this.pin.length - 1) {
                const nextInput = document.getElementById(
                    `pin-input-${inputIndex + 1}`
                ) as HTMLInputElement;
                if (nextInput) {
                    nextInput.focus();
                }
            }
        } else {
            input.value = '';
            this.pin[inputIndex] = '';
        }
    }

    openNotSignContract(contractId: number) {
        this.selectedContract = contractId;
        const url = `${this.configS.getValue(
            'hostBackend'
        )}/contract/preview?patientId=${null}&contractId=${contractId}`;
        window.open(url, '_blank');
    }

    openSignContract(contractId: number) {
        this.selectedContract = contractId;
        const url = `${this.configS.getValue(
            'hostBackend'
        )}/contract/patient/docpdf?contractId=${contractId}`;
        window.open(url, '_blank');
    }

    submitSmsCode(): void {
        const pinCode = this.pin.join('');
        if (pinCode.length < 6) return;
        this.isSubmitBtnDisabled = true;
        this.code = pinCode;
        if (!this.messageId || !this.contractId) {
            this.alertService.error(
                'Произошла ошибка, обновите страницу и попробуйте ещё раз.'
            );
            this.clearSubmitState();
            return;
        }
        lastValueFrom(
            this.ps.getContractConfirm(
                this.contractId,
                this.code,
                this.messageId
            )
        )
            .then((res) => {
                if (res.statuscode === 500) {
                    this.alertService.error(res.message);
                    return;
                }
                this.alertService.success(
                    `Договор с номером ${this.contractId} подписан`
                );
                clearInterval(this.timer!);
                this.countdown = 120;
                this.backToList();
                this.isSmsSend.emit(true);
            })
            .catch((e) => {
                this.alertService.error(
                    e.msg ||
                        'Ошибка LibreOffice, пожалуйста обратитесь в медицинскую организацию.'
                );
            })
            .finally(() => {
                this.clearSubmitState();
                this.getPayContractList();
            });
    }

    private getPayContractList(): void {
        this.ps.getPayContract().subscribe(
            (docs) => {
                this.payContracts = docs;
                this.docCountRec = this.documents.length;
                this.loadingDocs = false;
            },
            (err) => {
                this.docCountRec = 0;
                this.loadingDocs = false;
            }
        );
    }

    public getSms(): void {
        if (this.timer && this.countdown !== 120) {
            this.alertService.warn(
                'Для повторной отправки сообщения, дождитесь завершения таймера.'
            );
            return;
        }
        this.startTimer();
        this.ps.getSms(this.smsText).subscribe(
            (message) => {
                if (message) {
                    this.alertService.success(
                        'Сообщение с кодом отправлено на ваш номер телефона.'
                    );
                    this.messageId = message.response?.messageId;
                    return;
                }
            },
            (err) => {
                // console.log(err);
                this.alertService.error(err.msg);
            }
        );
    }

    public disablePay(id: number): boolean {
        return true;
    }

    signDoc(id: number) {
        this.signingDoc = !this.signingDoc;
        this.contractId = id;
        this.contractName = this.payContracts.find(
            (contract) => contract.template_id === id
        )?.template_name;
        this.getSms();
    }

    backToList(): void {
        this.signingDoc = false;
        // this.clearSubmitState();
    }
    clearSubmitState(): void {
        this.isSubmitBtnDisabled = false;
        this.clearForm();
    }

    // ngDoCheck() {
    //     const pinCheck = this.pin.join('');
    //     pinCheck.length <= 5
    //         ? (this.isSubmitBtnDisabled = false)
    //         : (this.isSubmitBtnDisabled = true);
    // }
}
