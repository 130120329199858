import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { dateToText } from '../components/application/global.function';
import aggrQuery from '../helpers/aggrQueryParams';
import {
    ICalendarData,
    IDep,
    IDoctor,
    IRecTalon,
    IRecTalonInit,
    IRnumbDate,
    ISpec,
    IStaticFilter,
    ITalonInfo,
    ITalonResAppointment,
    ITalonResAttrs,
    ITalonResBlStatus,
    ITalonResCansel,
    ITalonResCPbyR,
    ITalonResPaymentTemp,
    ITalonsDaySetParams,
} from '../interfaces/record.interface';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { EventService } from './event/event.service';
import { Store } from './store.service';

@Injectable({
    providedIn: 'root',
})
export class RecordService {
    /* События календаря */
    CalendaryinitSubject = new Subject<ICalendarData>();
    CalendarysetParams(param: ICalendarData) {
        // Установить параметры для календаря
        if (this.CalendaryinitSubject) {
            this.CalendaryinitSubject.next(param);
        }
    }
    // Событие инициализации
    CalendaryonInit$(): Observable<ICalendarData> {
        return this.CalendaryinitSubject.asObservable();
    }
    // ******************************************************
    /* События талонов */
    TalonsInitSubject = new Subject<IRecTalonInit>();
    TalonsSetParams(param: IRecTalonInit) {
        // Передать список талонов
        if (this.TalonsInitSubject) {
            this.TalonsInitSubject.next(param);
        }
    }
    // Событие инициализации списка талонов
    TalonsOnInit$(): Observable<IRecTalonInit> {
        return this.TalonsInitSubject.asObservable();
    }

    TalonsDayInitSubject = new Subject<ITalonsDaySetParams>();
    TalonsDaySetParams(param: ITalonsDaySetParams) {
        // Передать выбранные день
        if (this.TalonsDayInitSubject) {
            this.TalonsDayInitSubject.next(param);
        }
    }
    // Событие инициализации списка талонов
    TalonsDayOnInit$(): Observable<ITalonsDaySetParams> {
        return this.TalonsDayInitSubject.asObservable();
    }
    // *****************************************************
    /* События выбор талонов*/
    RnumbDateinitSubject = new Subject<IRnumbDate>();
    RnumbDateSetParams(param: IRnumbDate) {
        // Установить параметры для Блок Rnumb
        if (this.RnumbDateinitSubject) {
            this.RnumbDateinitSubject.next(param);
            this.eventService.emit('calendar:loading');
        }
    }
    // Событие инициализации
    RnumbDateOnInit$(): Observable<IRnumbDate> {
        return this.RnumbDateinitSubject.asObservable();
    }

    /* События списка подразделений */
    DepInitSubject = new Subject<IDep[]>();
    DepSetParams(param: IDep[]) {
        // Установить параметры для Блок Rnumb
        if (this.DepInitSubject) {
            this.DepInitSubject.next(param);
        }
    }
    // Событие инициализации
    DepOnInit$(): Observable<IDep[]> {
        return this.DepInitSubject.asObservable();
    }

    // Событие Выбора Dep
    DepSelectSubject = new Subject<IDep>();
    DepSelect(param: IDep) {
        // Установить параметры для Блок Rnumb
        if (this.DepSelectSubject) {
            this.DepSelectSubject.next(param);
        }
    }
    // Событие Выбора
    DepOnSelect$(): Observable<IDep> {
        return this.DepSelectSubject.asObservable();
    }

    // *****************************************

    constructor(
        private httpNew: HttpNewService,
        private auth: AuthService,
        private eventService: EventService
    ) {}

    /**
     * Список специальностей
     * @param parameters
     * beginDate: Date,
     * endDate: Date
     * staticId - id фильтра
     */
    public getSpecList(
        beginDate: Date,
        endDate: Date,
        staticId: number | null
    ): Observable<ISpec[]> {
        let url = '/record/spec/list';
        if (beginDate && endDate) {
            url =
                url +
                `?beginDate=${dateToText(beginDate)}&endDate=${dateToText(
                    endDate
                )}`;
        }
        if (staticId) {
            url = url + `&stacId=${staticId}`;
        }
        return this.httpNew.get(url);
    }

    /**
     * Список докторов
     * @param parameters
     */
    /*
  public getHistoryEvents(pStart?: number , pEnd?: number): Observable<IHistoryEvents[]> {
    let url = '/history/events';
    if (pStart && pEnd) {
      url = url + `?start=${pStart}&end=${pEnd}`;
    }
*/

    public getDocList(
        pSpecId: number,
        beginDate: Date,
        endDate: Date,
        stacId: number | null
    ): Observable<IDoctor[]> {
        let url = '/record/doc/list';
        if (pSpecId && beginDate && endDate) {
            url =
                url +
                `?specID=${pSpecId}&beginDate=${dateToText(
                    beginDate
                )}&endDate=${dateToText(endDate)}`;
        }
        if (stacId) {
            url = url + `&stacId=${stacId}`;
        }
        return this.httpNew.get(url);
    }

    public getRnumbList(
        pDoctorId: number,
        pSpecId: number | null,
        beginDate: Date,
        endDate: Date,
        stacId: number | null,
        srvIds: string | null
    ): Observable<IRecTalon[]> {
        const url = '/record/rnumb/list';
        const params = {
            specID: pSpecId,
            doctorID: pDoctorId,
            beginDate: dateToText(beginDate),
            endDate: dateToText(endDate),
            stacId,
            srvIds,
        };
        return this.httpNew.get(url, params);
    }

    /* Список фильтров кнопок (метки на талонах) */
    public getStacfilterList(): Observable<IStaticFilter[]> {
        const url = '/record/stacfilter/list';
        return this.httpNew.get(url);
    }

    /* Список подразделений */
    public getDepList(
        pDoctorId: number,
        pSpecId: number | null,
        servId: number | null
    ): Observable<IDep[]> {
        const url = '/record/dep/list';
        const params = { doctorID: pDoctorId, specID: pSpecId, servID: servId };
        return this.httpNew.get(url, params);
    }

    /* Информация о талоне */
    public getRnumbInfo(
        rnumbID: number,
        srvID: string | null = null
    ): Observable<ITalonInfo[]> {
        const url = '/record/rnumb/info';
        const params = { rnumbID, srvID };
        return this.httpNew.get(url, params);
    }

    /* Блокировка талона */
    public setRnumbBlStatus(rnumbID: number): Observable<ITalonResBlStatus> {
        let url = '/record/rnumb/blstatus';
        const params = { rnumbID };
        return this.httpNew.get(url, params);
    }

    /* Запись талона */
    public setAppointment(
        rnumbID: number,
        srvID: number | null
    ): Observable<ITalonResAppointment> {
        const url = '/record/rnumb/appointment';
        const queryParams: Record<string, number | null> = {
            rnumbID,
            customerId: this.getCookieData(),
            srvID,
        };
        // if (srvID !== null) queryParams.srvID = srvID;

        return this.httpNew.get(url, queryParams);
    }

    public setAppointmentExtra(docdepID: number, srvIDs: string, date: string) {
        // console.log(date);
        const url = '/record/rnumb/appointment-extra';
        const data: Record<string, number | null | string> = {
            docdepID,
            srvIDs,
            date,
            customerId: this.getCookieData(),
        };
        return this.httpNew.post(url, data);
    }

    /* Проверка статусов талона */
    public getAttrs(
        rnumbID: number,
        srvID: number | null
    ): Observable<ITalonResAttrs> {
        const url = '/record/rnumb/attrs';
        const queryParams = { rnumbID, srvID };
        return this.httpNew.get(url, queryParams);
    }
    public getCookieData() {
        let cookieData = null;
        if (Store.getDataString('ParentCookieName')) {
            cookieData = Store.getCookieData<{ patient_id: number }>();
        }
        return cookieData ? cookieData.patient_id : null;
    }

    /* Создание платежа после записи на номерок */
    public getCPbyR(
        rnumbID: number,
        srvID: number | null
    ): Observable<ITalonResCPbyR> {
        const url = '/record/rnumb/cpbyr';
        // let cookieData = null;
        // if (Store.getDataString('ParentCookieName')) {
        //     cookieData = Store.getCookieData<{ patient_id: number }>();
        // }
        const queryParams = {
            rnumbID,
            srvID,
            customerId: this.getCookieData(),
        };
        return this.httpNew.get(url, queryParams);
    }

    /* Создание Видео-конференции после записи на номерок */
    public getСreateConference(rnumbID: number): Observable<any> {
        const url = '/trueconf/createConference';
        // url = url + `?rnumbID=${rnumbID}`;
        const params = { rnumbID };
        return this.httpNew.get(url, params);
    }

    /* Оплата отложенного платежа */
    public getPaymentTemp(
        paymentID: number,
        email: string | null,
        phone: string | null
    ): Observable<ITalonResPaymentTemp> {
        const url = '/record/rnumb/paymenttemp';
        const params = {
            paymentID,
            email,
            phone,
        };
        return this.httpNew.get(url, params);
    }

    /* Отмена талона */
    public getRnumbCancel(rnumbID: number): Observable<ITalonResCansel> {
        const url = '/record/rnumb/cancel';
        const queryParams = {
            rnumbID,
            customerId: this.getCookieData(),
        };
        return this.httpNew.get(url, queryParams);
    }

    /* Разблокировка талона талона */
    public getRnumbUnlock(rnumbID: number): Observable<ITalonResCansel> {
        const url = '/record/rnumb/unlock';
        // if (rnumbID) {
        //     url = url + `?rnumbID=${rnumbID}`;
        // }
        const params = { rnumbID };
        return this.httpNew.get(url, params);
    }

    /* создание интервального расписания */
    public getRnumbCreateInterval(
        intervalID: number,
        dat_bgn: string,
        dat_end: string
    ): Observable<ITalonResCansel> {
        const url = '/record/rnumb/create/interval';
        const params = {
            interval_id: intervalID,
            dat_bgn: dat_bgn,
            dat_end: dat_end,
        };
        return this.httpNew.post(url, params);
    }

    // FOR REFACTORED CALENDAR
    public getRnumbListv2(params: IRnumbDate) {
        let url = '/record/rnumb/list';
        const {
            specId,
            doctorId,
            periodStart,
            periodEnd,
            staticFilterSelected,
        } = params;

        const normalizedParams = aggrQuery({
            specID: specId,
            doctorID: doctorId,
            beginDate: dateToText(periodStart),
            endDate: dateToText(periodEnd),
            stacId: staticFilterSelected?.keyid,
            srvIds: params.srvId || params.srv?.keyid,
        });

        return this.httpNew.get(url, normalizedParams);
    }

    public getDepListv2(params: IRnumbDate) {
        const url = '/record/dep/list';

        const normalizedParams = {
            doctorID: params.doctorId,

            specID: params.specId,
            servID: params.srvId,
        };

        return this.httpNew.get(url, aggrQuery(normalizedParams));
    }

    public getRnumbInfoExtra(params: {
        docdepID: number;
        date: Date;
        srvIDs: string;
    }) {
        const url = '/record/rnumb/info-extra';

        // console.log('RS: getRnumbInfoExtra: ', { params });

        return this.httpNew.get(url, aggrQuery(params));
    }
}
