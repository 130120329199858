<div class="app-grid">
    <app-menu-left [patientName]="patientName" class="sidebar"></app-menu-left>
    <app-header
        (patientNameChange)="onPatientNameChange($event)"
        class="header"
    ></app-header>

    <div class="cls-outlet" #ContentBloc>
        <router-outlet></router-outlet>
    </div>

    <!-- <footer class="footer"></footer> -->
</div>
