import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { AuthService } from 'src/app/services/auth.service';
import { CookieConsentService } from 'src/app/services/cookie-consent-service';
import { Store } from 'src/app/services/store.service';
import { AlertService } from 'src/lib/ariadna/alert';
@Component({
    selector: 'app-family-access',
    templateUrl: './family-access.component.html',
    styleUrls: ['./family-access.component.scss'],
})
export class FamilyAccessComponent {
    public familyList: any[] = [];
    public isLoadData: boolean = false;
    public selectedFamilyMemberId: number | null = null;
    constructor(
        private httpService: HttpNewService,
        private dialogRef: MatDialog,
        private auth: AuthService,
        private cookieService: CookieConsentService,
        private alert: AlertService
    ) {}

    ngOnInit(): void {
        this.getFamilyAccessList();
    }

    async getFamilyAccessList(): Promise<void> {
        try {
            this.isLoadData = true;
            const result = await lastValueFrom(
                this.httpService.get('/family/list/relatives')
            );
            this.familyList = result;
        } catch (e: any) {
            this.alert.error(`${e.error.msg}`);
        } finally {
            this.isLoadData = false;
        }
    }
    async submitMember() {
        try {
            this.isLoadData = true;
            const result = await lastValueFrom(
                this.httpService.get('/family/set/cookie', {
                    cookieId: this.selectedFamilyMemberId,
                    isSetParentCookie: 'false',
                })
            );
            Store.setStringData('ParentCookieName', result.name);
            setTimeout(() => {
                this.isLoadData = false;
                window.location.reload();
            }, 500);
            return;
        } catch (e: any) {
            this.alert.error(
                `Произошла непредвиденная ошибка: ${e.message}`,
                2000
            );
            setTimeout(() => {
                this.isLoadData = false;
                window.location.reload();
            }, 2000);
        }
    }

    closeModal() {
        this.dialogRef.closeAll();
    }
}
