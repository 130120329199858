import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    showLeftRightAnimation,
    showOpacityAnimation,
} from '../../../animations/show-left-right.animation';
import { ConfigService } from '../../../services/application/config.service.';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/lib/ariadna/alert';
import { AuthService } from '../../../services/auth.service';
import {
    IAuthPatients,
    ICodeSMS,
    ITokenAndPatientId,
} from '../../../interfaces/patient';
import { Router } from '@angular/router';
import { LoadService } from '../../../services/application/load.service';

import {
    CaptchaService,
    ICaptcha,
} from 'src/app/services/captcha/captcha.service';

@Component({
    selector: 'app-auth-phone',
    templateUrl: './auth-phone.component.html',
    styleUrls: ['./auth-phone.component.scss'],
    animations: [showOpacityAnimation],
})
export class AuthPhoneComponent implements OnInit, AfterViewInit {
    animState = 'on';
    public dogPREVIEW_REQUIRED: boolean = false; // Обязательность предпросмотра

    captcha: ICaptcha | undefined = undefined;
    // captchaId: number = 0;

    public tmpToken: ITokenAndPatientId | null = null;
    public codeSMS: ICodeSMS = {
        char1: null,
        char2: null,
        char3: null,
        char4: null,
    };

    public step = 0; // Шаги авторизации 0- ввод телефона и капчи, 1-ввод кода из СМС, 2- выбор пациента, 3-договора
    frmRegPhone!: FormGroup;
    get phoneNumber(): string {
        if (this.frmRegPhone && this.frmRegPhone.controls['phone']) {
            return this.frmRegPhone.controls['phone'].value;
        } else {
            return '';
        }
    }

    @Output() onChanged = new EventEmitter<number>(); // выбран
    @Output() onError = new EventEmitter<string>(); // Ошибка
    public captchaJpg = ''; // картинка дла КАПЧИ
    loading = false; // Загрузка
    public patients: IAuthPatients[] = []; // Список найденных пациентов
    @ViewChild('fieldPhone') fieldPhone: ElementRef | undefined;

    constructor(
        private configS: ConfigService,
        private alertService: AlertService,
        private auth: AuthService,
        private router: Router,
        private load: LoadService,

        private captchaService: CaptchaService
    ) {
        if (
            load.configData.pages.login &&
            load.configData.pages.login.DogPREVIEW_REQUIRED
        ) {
            this.dogPREVIEW_REQUIRED =
                load.configData.pages.login.DogPREVIEW_REQUIRED;
        }
    }

    ngOnInit(): void {
        this.initialize();

        this.getCaptcha();
        //      this.step=1;  // test
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.fieldPhone) {
                this.fieldPhone.nativeElement.focus();
            }
        }, 1);
    }

    private initialize(): void {
        this.step = 0;
        this.frmRegPhone = new FormGroup({});
        this.frmRegPhone.addControl(
            'captchaValue',
            new FormControl('', [Validators.required, Validators.minLength(4)])
        );

        // this.frmRegPhone.addControl(
        //     'captchaSolid',
        //     new FormControl('', [Validators.required, Validators.minLength(4)])
        // );

        //        this.frmRegPhone.addControl('phone', new FormControl('', [Validators.required, Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]));
        // Для тестов
        this.frmRegPhone.addControl(
            'phone',
            new FormControl('', [Validators.required, Validators.minLength(2)])
        );
    }

    whenAnimate(event: any) {
        if (event.toState === 'off') {
            this.onChanged.emit(0);
        }
    }

    /*
    goAuth(): void {
//        this.animStaus = 'off';
        this.animState = 'off';
    }
*/

    goReg(pVal: number): void {
        this.onChanged.emit(pVal);
        this.animState = 'off';
    }

    //кнопка продолжить
    regPhone(): void {
        this.loading = true;
        //
        this.auth
            .patientforphone$(
                this.frmRegPhone.value,
                this.captcha?.id.toString()
            )
            .subscribe(
                (result) => {
                    console.log(result.success);
                    //
                    if (result && result.success) {
                        //  this.alertService.success('вам на телефон выслана СМС с кодом', 9000);
                        this.patients = result.data;
                        this.step = 1;
                        this.onError.emit('');
                        this.setFocusFieldCode(1);
                    } else {
                        this.refreshCaptcha();
                        if (result.msg === 'CaptchaError') {
                            this.onError.emit('Проверьте код капчи!');
                        } else {
                            this.onError.emit('Проверьте введенные данные!');
                        }
                    }
                    this.loading = false;
                },
                (error) => {
                    this.alertService.error(
                        'Проверьте введенные данные!',
                        9000
                    );
                    this.loading = false;
                    this.refreshCaptcha();
                }
            );
    }

    // проверка кода из СМС
    regCodeCMC() {
        if (this.patients.length > 1) {
            this.step = 2;
        } else {
            // Авторизация
        }
    }

    // выбор пациента и авторизация
    regSelectPatient(p: IAuthPatients) {
        this.auth
            .loginphone$(
                p.patient_id
                // this.frmRegPhone.controls['captchaValue'].value,
                // this.frmRegPhone.controls['captchaSolid'].value
            )
            .subscribe(
                (result: any) => {
                    if (result.token && result.patientId) {
                        this.loading = false;
                        this.tmpToken = {
                            token: result.token,
                            patientId: result.patientId,
                        };
                        if (result.ext) {
                            this.tmpToken.ext = result.ext;
                        }

                        if (p.count_dog && p.count_dog > 0) {
                            this.step = 3;
                        } else {
                            this.loginOK();
                        }
                    } else {
                        this.refreshCaptcha();
                    }
                    this.loading = false;
                },
                (error: any) => {
                    this.refreshCaptcha();
                    this.alertService.error(
                        'Ошибка, попробуйте повторить ввод через 5 мин!',
                        9000
                    );
                    this.loading = false;
                }
            );
    }

    // Обновление картинки КАПЧИ
    // public refreshCaptcha() {
    //     //

    //     let v_time = new Date().getTime();
    //     //
    //     this.frmRegPhone.patchValue({ captchaSolid: v_time });
    //     this.frmRegPhone.patchValue({ captcha: '' });
    //     this.captchaJpg =
    //         this.configS.getValue('hostBackend') +
    //         '/api/captcha.png?solid=' +
    //         v_time;
    // }

    // Получение капчи
    // Получение капчи
    async getCaptcha() {
        this.loading = true;
        this.captcha = await this.captchaService.fetchCaptcha();
        this.loading = false;
    }

    // Обновление капчи
    public refreshCaptcha() {
        this.captcha = undefined;
        this.frmRegPhone.controls['captcha']?.setValue('');
        this.getCaptcha();
    }

    // Смена фокуса
    setFocusFieldCode(n: number) {
        setTimeout(() => {
            let dom = document.getElementById('fieldCode' + n);
            if (dom) {
                dom.focus();
            }
        }, 1);
    }

    // Обработка клавиш на DEL
    onKeyDownCodeSMS(e: any) {
        //
        if (e.key == 'Delete' || e.key == 'Backspace') {
            if (!this.codeSMS.char4) {
                if (!this.codeSMS.char3) {
                    if (!this.codeSMS.char2) {
                        if (!this.codeSMS.char1) {
                        } else {
                            this.codeSMS.char1 = null;
                            this.setFocusFieldCode(1);
                        }
                    } else {
                        this.codeSMS.char2 = null;
                        this.setFocusFieldCode(2);
                    }
                } else {
                    this.codeSMS.char3 = null;
                    this.setFocusFieldCode(3);
                }
            } else {
                this.codeSMS.char4 = null;
                this.setFocusFieldCode(4);
            }
        }
    }

    // Ввод кода из СМС
    onCodeSMSChange() {
        //
        if (this.codeSMS.char1) {
            if (this.codeSMS.char2) {
                if (this.codeSMS.char3) {
                    if (this.codeSMS.char4) {
                        this.regCodeCMC();
                    } else {
                        this.setFocusFieldCode(4);
                    }
                } else {
                    this.setFocusFieldCode(3);
                }
            } else {
                this.setFocusFieldCode(2);
            }
        } else {
            this.setFocusFieldCode(1);
        }
    }

    private loginOK() {
        this.auth.loginOk$(
            this.tmpToken!.token,
            this.tmpToken!.patientId,
            this.tmpToken!.ext!
        );
        this.router.navigate(['/home']);
    }

    public onErrorDog(ev: string = ''): void {
        this.onError.emit(ev);
    }

    public onEventDog(ev: string = ''): void {
        //
        switch (ev) {
            case 'LOGINOK': {
                this.loginOK();
                break;
            }
            case 'LOGOUT': {
                this.step = 2;
                break;
            }
        }
    }
}
