import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { getNameDay } from 'src/app/components/application/global.function';
import { ConfigService } from 'src/app/services/application/config.service.';
import { AlertService } from 'src/lib/ariadna/alert';
import { HistoryService } from '../../services/history.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { DicomViewModalComponent } from 'src/app/components/history/dicom-view-modal/dicom-view-modal.component';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { LoadService } from 'src/app/services/application/load.service';

@Component({
    selector: 'app-history-item-mobile',
    templateUrl: './history-item.component.html',
    styleUrls: ['./history-item.component.scss'],
})
export class HistoryItemComponentMobile implements OnInit {
    @ViewChild('pdfModal')
    pdfModal!: ElementRef;

    historyId: number = 0;
    doctorid: number = 0;
    historyType: string = '';
    resHtml: string = '';
    historyDatDay: string = '';
    historyName: string = '';
    historySpec: string = '';
    historyTypetext: string = '';
    historyDepname: string = '';
    historyDat: Date;
    historyTime: Date;
    accessionNumber = '';
    studyDate = '';
    studyIUID = '';

    pdf: Map<string, ArrayBuffer> = new Map();
    pdfImageURL = 'assets/img/svg/pdf-view.svg';
    isLoading = false;
    pdfLoading = false;

    txtPhone = '';

    constructor(
        private hs: HistoryService,
        @Inject(MAT_DIALOG_DATA) public dataIN: any,
        private configS: ConfigService,
        private alert: AlertService,
        private modalService: NgbModal,
        private http: HttpNewService,
        private load: LoadService
    ) {
        this.historyDatDay = dataIN.dtSort;
        this.historyDat = dataIN.dat;
        this.historyName = dataIN.doctor;
        this.historySpec = dataIN.spec;
        this.historyTypetext = dataIN.typetext;
        this.historyDepname = dataIN.dep_name;
        this.historyTime = dataIN.time;
        this.doctorid = dataIN.doctorid;
        this.accessionNumber = dataIN.accession_no;
        this.studyDate = dataIN.study_date;
        this.studyIUID = dataIN.study_iuid;

        if (dataIN && dataIN.typehistory && dataIN.keyid) {
            this.historyId = dataIN.keyid;
            this.historyType = dataIN.typehistory;
        }

        if (load.configData.pages.services.WEB_LK_SERVICES_INFO) {
            this.txtPhone = load.configData.pages.services.WEB_LK_SERVICES_INFO;
        }
    }

    /**
     * В случае если изображение на сервере не найдено то грузим локальное изображение
     * @param event
     */
    public errorHandlerIMG(event: any, type: string): void {
        event.target.src = `${this.configS.getValue(
            'hostBackend'
        )}/img/${type}/not.png`;
    }

    async gotToDownload(id: number, tp: string, $event: MouseEvent) {
        $event.stopPropagation();
        this.pdfLoading = true;
        const statePdf: number = this.load.configData.pages.history.PDFButtonState ?? 0;
        let statePdfFull: number = statePdf ? 1 : 0;
        switch (statePdfFull) {
            case 1:
                try {
                    await lastValueFrom(
                        this.http.get(
                            `/history/events/item/visitpdf/${tp}/${id}`,
                            null,
                            false
                        )
                    ).then(v => {
                        this.pdf = v;
                        this.modalService.open(this.pdfModal);
                    })
                    .catch(e => this.alert.warn(e))
                    .finally(() =>  this.pdfLoading = false);
                } catch(e) {
                    console.log(e);
                    this.alert.warn('Произошла ошибка')
                }
                break;
            default:
              try {
                const res = await lastValueFrom(
                    this.http.get(this.getDownloadUrlForPDF(id, tp), null, true)
                );
                const emptyPage = window.open('about:blank', '_blank');
                if (emptyPage) {
                    const file = new Blob([res], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    emptyPage.location.href = fileURL;
                }
               } catch (error) {
                    this.alert.error('Ошибка загрузки');
              } finally {
                    this.pdfLoading = false;
            }
        }
    }
    getDownloadUrlForPDF(id: number, tp: string) {
        return `/history/events/item/${tp}/${id}.pdf`;
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.GetItem(this.historyId).then(() => (this.isLoading = false));
    }

    getImgSrcDoc(id: number | null | undefined): string {
        return `${this.configS.getValue('hostBackend')}/img/doc/${id}.png`;
    }

    async GetItem(id: number) {
        try {
            const res = await lastValueFrom(
                this.hs.getHistoryItem({
                    id: this.historyId,
                    typeHistory: this.historyType,
                })
            );

            this.resHtml = '';

            res.forEach((item) => {
                this.resHtml = this.resHtml + item.text;
            });
        } catch (error) {
            //Что-то странное. Постоянная ошибка not params
        }
    }

    /* День и время*/
    getTalonDateTime(dt: Date | undefined): string {
        if (dt) {
            return (
                moment(dt, 'DD.MM.YYYY hh:mm').format('DD MMMM') +
                ' в ' +
                moment(dt, 'DD.MM.YYYY hh:mm').format('HH:mm')
            );
        } else {
            return '';
        }
    }

    /* День недели*/
    getNameDay(dt: Date | undefined): string {
        if (dt) {
            const date = moment(dt, 'DD.MM.YYYY hh:mm').toDate();

            return getNameDay(date);
        } else {
            return '';
        }
    }

    getFIODoc(fio: string | null): string {
        let result = '';

        if (fio) {
            const fioArray = fio.split(' ');
            const lastname = fioArray[0];
            const firstname = fioArray[1];
            const secondname = fioArray[2];

            if (lastname?.length > 0) {
                result = lastname;
            }
            if (firstname?.length > 0) {
                result += ' ' + firstname[0] + '.';
            }
            if (secondname?.length > 0) {
                result += ' ' + secondname[0] + '.';
            }
        }

        return result;
    }

    openDicomModal(e: MouseEvent) {
        e.stopPropagation();
        const modal = this.modalService.open(DicomViewModalComponent);
        modal.componentInstance.accessionNumber = this.accessionNumber;
        modal.componentInstance.studyDate = this.studyDate
            .match(/(\d{0,4})(\d{0,2})(\d{0,2})/)
            ?.splice(1, 4)
            .reverse()
            .join('.');
        modal.componentInstance.studyInstanceUID = this.studyIUID;
    }

    getDownloadUrl(): string {
        return (
            this.configS.getValue('hostBackend') +
            `/history/visit/dicom/download?studyIUID=${this.studyIUID}`
        );
    }

    pdfToDownload(event: Event, item:any) {
        event.preventDefault();
        const pdfBuffer: ArrayBuffer = item.value.content.data;
        const arr = new Uint8Array(pdfBuffer);
        const blob = new Blob([arr], { type: 'application/pdf'});
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, "_blank");
      }

      mouseEnt(event: any) {
        event.stopPropagation();
        event.target.children[0].src = 'assets/img/svg/pdf_hover.svg'
      }

      mouseOut(event: any) {
        event.stopPropagation();
        event.target.children[0].src = 'assets/img/svg/pdf-view.svg';
      }
}
