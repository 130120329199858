import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpNewService } from '../application/http-new.service';
import { AuthService } from '../auth.service';

export interface ICaptcha {
    id: string;
    svg: string;
    value?: string;
}

interface CaptchaVerifyResult {
    valid: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CaptchaService {
    constructor(private http: HttpNewService, private auth: AuthService) {}

    /**
     * Создать и получить капчу
     * @param parameters
     * @returns {Observable<IAbonement[]>}
     **/
    public fetchCaptcha(): Promise<ICaptcha> {
        return lastValueFrom<ICaptcha>(
            this.http.get('/api/captcha/create').pipe(first())
        );
    }

    /**
     * Создать и получить капчу
     * @param parameters
     * @returns {Observable<IAbonement[]>}
     **/
    public verifyCaptcha(
        id: number,
        value: string
    ): Promise<CaptchaVerifyResult> {
        const data = {
            id,
            value,
        };

        return lastValueFrom<CaptchaVerifyResult>(
            this.http.post('/api/captcha/verify', data).pipe(first())
        );
    }
}
