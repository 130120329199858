import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
    Component,
    Injector,
    ViewChild,
    afterNextRender,
    inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { LoadService } from 'src/app/services/application/load.service';
import { PatientService } from 'src/app/services/patient.service';
import { AlertService } from 'src/lib/ariadna/alert';

interface IFeedbackOption {
    title: string;
    email: string[];
    subsubject?: string[];
}

interface FeedbackDTO {
    subject: string;
    mail_from: string;
    mail_to: string;
    content: string;
    subsubject?: string;
}
@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
    form: FormGroup = new FormGroup({
        subject: new FormControl('', Validators.required),
        subsubject: new FormControl(''),
        message: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.email, Validators.required]),
    });
    subjectOptions: IFeedbackOption[] = [];
    loading = false;
    protected patientEmail: string | null = null;
    private _injector = inject(Injector);
    @ViewChild('autosize') autosize!: CdkTextareaAutosize;
    triggerResize() {
        // Wait for content to render, then trigger textarea resize.
        afterNextRender(
            () => {
                this.autosize.resizeToFitContent(true);
            },
            {
                injector: this._injector,
            }
        );
    }

    constructor(
        private load: LoadService,
        private http: HttpNewService,
        private alertService: AlertService,
        private patientService: PatientService
    ) {
        if (load.configData.pages.feedback.length === 0) {
            alertService.error('Нет полей для формы обратной связи!');
            throw new Error('Нет полей для формы обратной связи!');
        }
    }

    async ngOnInit() {
        try {
            const subjectsRaw = await JSON.parse(
                this.load.configData.pages.feedback.subjects
            );
            this.subjectOptions = subjectsRaw['subjects'];
            const patientInfo = await lastValueFrom(
                this.patientService.getServerPatientInfo$()
            );
            this.patientEmail = patientInfo.email ?? null;
            if (this.patientEmail) {
                this.form.patchValue({
                    email: this.patientEmail,
                });
            }
        } catch (error) {
            console.error('Ошибка парсинга параметра обратной связи: ', error);
        }
    }
    async onSubmit() {
        this.loading = true;

        if (!this.form.valid) {
            this.alertService.error('Заполните все обязательные поля!');
            return;
        }

        const data: FeedbackDTO = {
            subject: this.form.controls['subject'].value.title,
            mail_from: this.form.controls['email'].value,
            mail_to: this.form.controls['subject'].value.email,
            content: this.form.controls['message'].value,
        };

        if (this.form.controls['subsubject'].value) {
            data['subsubject'] = this.form.controls['subsubject'].value;
        }

        try {
            await lastValueFrom(this.http.post('/api/feedback', data, false));

            // console.log(res, res.success);
            // if (!res.success) throw new Error('Ошибка при отправке обращения');
            this.alertService.success('Ваше обращение успешно отправлено!');
        } catch (error: any) {
            this.alertService.error('Не удалось отправить обращение!');
            console.error(error.msg);
        } finally {
            this.loading = false;
        }
    }
}
